import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import api from '../services/api';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SubscriptionButton = ({ className }) => {
  const handleSubscribe = async () => {
    console.log('Subscribe button clicked');
    try {
      console.log('Loading Stripe...');
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error("Stripe hasn't loaded correctly.");
      }
      console.log('Stripe loaded successfully');

      console.log('Creating checkout session...');
      const response = await api.post('/subscription/create-checkout-session');
      const { sessionId } = response.data;
      console.log('Checkout session created:', sessionId);

      console.log('Redirecting to Stripe checkout...');
      const result = await stripe.redirectToCheckout({
        sessionId: sessionId,
      });

      if (result.error) {
        console.error('Stripe redirect error:', result.error.message);
      }
    } catch (error) {
      console.error('Subscription error:', error);
    }
  };

  return (
    <button
      onClick={handleSubscribe}
      className={
        className ||
        'bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out'
      }
    >
      Upgrade to Premium
    </button>
  );
};

export default SubscriptionButton;