import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon, ClipboardDocumentListIcon, CalendarIcon, UsersIcon, ChartBarIcon } from '@heroicons/react/24/outline';

const FeatureItem = ({ icon, text }) => (
  <div className="flex flex-col items-center text-center p-4">
    <div className="bg-primary rounded-full p-3 mb-4">
      {icon}
    </div>
    <p className="text-gray-600">{text}</p>
  </div>
);

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-background flex flex-col justify-center items-center px-4 sm:px-6 lg:px-8 py-12">
      <div className="max-w-4xl w-full space-y-8 text-center">
        <div>
          <img className="mx-auto h-24 w-auto" src="/logo192.png" alt="Gaffer Logo" />
          <h1 className="mt-6 text-4xl font-extrabold text-secondary">
            Welcome to Gaffer
          </h1>
          <p className="mt-2 text-xl text-gray-600">
            Your ultimate soccer coaching assistant
          </p>
        </div>
        <div className="mt-8 space-y-4 flex flex-col items-center">
          <Link
            to="/register"
            className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-primary-dark md:py-4 md:text-lg md:px-10 transition duration-300 ease-in-out"
          >
            Get Started
            <ChevronRightIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
          </Link>
          <Link
            to="/login"
            className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-primary bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10 transition duration-300 ease-in-out"
          >
            Log In
          </Link>
        </div>
        <div className="mt-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <FeatureItem 
              icon={<ClipboardDocumentListIcon className="h-8 w-8 text-white" />}
              text="Create and manage practice drills"
            />
            <FeatureItem 
              icon={<CalendarIcon className="h-8 w-8 text-white" />}
              text="Build customized practice sessions"
            />
            <FeatureItem 
              icon={<UsersIcon className="h-8 w-8 text-white" />}
              text="Organize team rosters and player profiles"
            />
            <FeatureItem 
              icon={<ChartBarIcon className="h-8 w-8 text-white" />}
              text="Track player progress and performance"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;