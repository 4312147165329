import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { createRoster, getRosterById, updateRoster } from '../services/rostersService';
import { getPlayers, createPlayer } from '../services/playersService';
import { XCircleIcon, PlusCircleIcon  } from '@heroicons/react/24/solid';
import { useAuth } from './AuthContext';
import QuickAddPlayerModal from './QuickAddPlayerModal';

function RosterForm() {
    const { user } = useAuth();
    const { id } = useParams();
    const navigate = useNavigate();
    const [roster, setRoster] = useState({
        name: '',
        season: '',
        ageGroup: '',
        players: []
    });
    const [allPlayers, setAllPlayers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showQuickAddModal, setShowQuickAddModal] = useState(false);

    useEffect(() => {
        fetchPlayers();
        if (id) {
            fetchRoster(id);
        }
    }, [id]);

    const fetchPlayers = async () => {
        try {
            const data = await getPlayers();
            setAllPlayers(data);
        } catch (err) {
            setError('Failed to fetch players');
        }
    };

    const fetchRoster = async (rosterId) => {
        try {
            setLoading(true);
            const data = await getRosterById(rosterId);
            setRoster(data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch roster data');
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRoster(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            let savedRoster;
            if (id) {
                savedRoster = await updateRoster(id, roster);
            } else {
                savedRoster = await createRoster({
                    ...roster,
                    owner: user._id // Ensure the owner is set when creating a new roster
                });
            }
            setLoading(false);
            navigate(`/rosters/${savedRoster._id}`);
        } catch (err) {
            setError('Failed to save roster');
            setLoading(false);
        }
    };

    const addPlayer = (player) => {
        if (!roster.players.some(p => p._id === player._id)) {
            setRoster(prev => ({
                ...prev,
                players: [...prev.players, player]
            }));
        }
        setSearchTerm('');
    };

    const handleQuickAddPlayer = async (playerData) => {
        try {
            const newPlayerData = {
                ...playerData,
                parentContact: playerData.parentContact || '',
                notes: playerData.notes || '',
                user: null
            };
            delete newPlayerData.user;

            const newPlayer = await createPlayer(newPlayerData);
            
            // Add the new player to the roster
            addPlayer(newPlayer);
            
            // Update allPlayers state
            setAllPlayers(prev => [...prev, newPlayer]);
            
            // Clear the search term
            setSearchTerm('');
        } catch (err) {
            setError('Failed to add new player: ' + err.message);
        }
    };

    const removePlayer = (playerId) => {
        setRoster(prev => ({
            ...prev,
            players: prev.players.filter(p => p._id !== playerId)
        }));
    };

    const filteredPlayers = allPlayers.filter(player => 
        player.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        !roster.players.some(p => p._id === player._id)
    );

    if (loading) return (
        <div className="max-w-4xl mx-auto p-6">
          <div className="animate-pulse space-y-4">
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            <div className="h-10 bg-gray-200 rounded w-1/2"></div>
            <div className="space-y-2">
              {[...Array(5)].map((_, index) => (
                <div key={index} className="h-20 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
      );

    return (
        <div className="max-w-2xl mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6 text-primary">{id ? 'Edit' : 'Create'} Roster</h1>
            {error && <div className="text-red-500 mb-4">{error}</div>}
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block mb-1">Roster Name</label>
                    <input type="text" name="name" value={roster.name} onChange={handleChange} required className="w-full p-2 border rounded" />
                </div>
                <div>
                    <label className="block mb-1">Season</label>
                    <input type="text" name="season" value={roster.season} onChange={handleChange} required className="w-full p-2 border rounded" />
                </div>
                <div>
                    <label className="block mb-1">Age Group</label>
                    <input type="text" name="ageGroup" value={roster.ageGroup} onChange={handleChange} required className="w-full p-2 border rounded" />
                </div>
                <div>
                    <h3 className="font-semibold mb-2">Players</h3>
                    <div className="mb-2">
                        <input 
                            type="text" 
                            placeholder="Search players..." 
                            value={searchTerm} 
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full p-2 border rounded"
                        />
                    </div>
                    {searchTerm && (
                        <ul className="max-h-40 overflow-y-auto border rounded mb-4">
                            {filteredPlayers.map(player => (
                                <li 
                                    key={player._id} 
                                    className="p-2 hover:bg-gray-100 cursor-pointer"
                                    onClick={() => addPlayer(player)}
                                >
                                    {player.name} - {player.position}
                                </li>
                            ))}
                            {filteredPlayers.length === 0 && (
                                <li 
                                    className="p-2 hover:bg-gray-100 cursor-pointer text-primary"
                                    onClick={() => setShowQuickAddModal(true)}
                                >
                                    <PlusCircleIcon className="h-5 w-5 inline mr-2" />
                                    Quick Add "{searchTerm}"
                                </li>
                            )}
                        </ul>
                    )}
                    <div className="space-y-2">
                        {roster.players.map(player => (
                            <div key={player._id} className="flex justify-between items-center bg-gray-100 p-2 rounded">
                                <span>{player.name} - {player.position}</span>
                                <button 
                                    type="button" 
                                    onClick={() => removePlayer(player._id)}
                                    className="text-red-500 hover:text-red-700"
                                >
                                    <XCircleIcon className="h-5 w-5" />
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
                <button type="submit" className="bg-primary text-white px-4 py-2 rounded hover:bg-primary-dark">Save Roster</button>
            </form>
            {showQuickAddModal && (
                <QuickAddPlayerModal 
                    onClose={() => setShowQuickAddModal(false)}
                    onAddPlayer={handleQuickAddPlayer}
                    initialName={searchTerm}
                />
            )}
        </div>
    );
}

export default RosterForm;