import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SubscriptionCancel = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="max-w-4xl mx-auto p-6 text-center">
      <h1 className="text-3xl font-bold mb-4 text-primary">Subscription Cancelled</h1>
      <p className="mb-4">Your subscription process was cancelled.</p>
      <p>You will be redirected to the home page shortly.</p>
    </div>
  );
};

export default SubscriptionCancel;