import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getDrills, getPopularDrills, incrementDrillUsage } from '../services/drillsService';
import { createPracticeSession } from '../services/practiceSessionsService';
import { getSessionTemplates } from '../services/sessionTemplatesService';
import { useNavigate } from 'react-router-dom';
import { TrashIcon, SparklesIcon, ChevronDownIcon, ChevronUpIcon, PlusCircleIcon } from '@heroicons/react/24/outline'; // Make sure to install @heroicons/react
import { useAuth } from './AuthContext';
import SessionTemplateOverview from './SessionTemplateOverview';
import DrillSelectionModal from './DrillSelectionModal';

const DrillItem = ({ drill, index, moveDrill, removeDrill, isSessionDrill, onAdd }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'DRILL',
    item: { id: drill._id, index, isSessionDrill },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'DRILL',
    hover(item, monitor) {
      if (!isSessionDrill || item.isSessionDrill !== isSessionDrill) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveDrill(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const ref = React.useRef(null);
  const dragDropRef = drag(drop(ref));

  return (
    <div ref={dragDropRef} className={`bg-white p-4 mb-2 rounded shadow ${isDragging ? 'opacity-50' : ''} relative`}>
      <h3 className="font-semibold">{drill.title}</h3>
      <p className="text-sm text-gray-600">Duration: {drill.duration} minutes</p>
      <p className="text-sm text-gray-600">Age Group: {drill.ageGroup}</p>
      <div className="flex flex-wrap gap-1 mt-1">
        {drill.tags.map((skill, index) => (
          <span key={index} className="bg-primary text-white px-2 py-1 rounded-full text-xs">
            {skill}
          </span>
        ))}
      </div>
      {isSessionDrill ? (
        <button
          onClick={() => removeDrill(drill._id)}
          className="absolute top-2 right-2 text-gray-500 hover:text-red-500"
          title="Remove drill"
        >
          <TrashIcon className="h-5 w-5" />
        </button>
      ) : (
        <button
          onClick={() => onAdd(drill)}
          className="absolute top-2 right-2 text-gray-500 hover:text-green-500"
          title="Add drill"
        >
          <PlusCircleIcon className="h-5 w-5" />
        </button>
      )}
    </div>
  );
};

const MultiSelect = ({ options, value, onChange, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    const newValue = value.includes(option)
      ? value.filter(item => item !== option)
      : [...value, option];
    onChange(newValue);
  };

  return (
    <div className="relative">
      <div
        className="w-full border border-gray-300 rounded-md shadow-sm p-2 bg-white cursor-pointer"
        onClick={handleToggle}
      >
        {value.length > 0 ? value.join(', ') : placeholder}
      </div>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
          {options.map((option) => (
            <div
              key={option}
              className={`p-2 cursor-pointer ${
                value.includes(option) 
                  ? 'bg-primary text-white hover:bg-primary-dark' 
                  : 'hover:bg-gray-100'
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const PracticeSessionBuilder = () => {
  const [allDrills, setAllDrills] = useState([]);
  const [availableDrills, setAvailableDrills] = useState([]);
  const [sessionDrills, setSessionDrills] = useState([]);
  const [sessionDetails, setSessionDetails] = useState({
    date: '',
    time: '',
    location: '',
    notes: '',
    modules: []
  });
  const [showAvailableDrills, setShowAvailableDrills] = useState(false);
  const [showSessionDrills, setShowSessionDrills] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterSkillTypes, setFilterSkillTypes] = useState([]);
  const [filterAgeGroups, setFilterAgeGroups] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentModule, setCurrentModule] = useState(null);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('default'); // New state for sorting
  const { user } = useAuth();
  const navigate = useNavigate();

  const fetchDrills = useCallback(async () => {
    try {
      let drillsData;
      if (sortBy === 'popular') {
        drillsData = await getPopularDrills();
      } else {
        drillsData = await getDrills();
      }
      setAllDrills(drillsData.drills || []);
      setAvailableDrills(drillsData.drills || []);
    } catch (error) {
      console.error("Error fetching drills:", error);
      setError('Failed to fetch drills');
    }
  }, [sortBy]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [drillsData, templatesData] = await Promise.all([
          fetchDrills(),
          getSessionTemplates()
        ]);
        setTemplates(templatesData || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [fetchDrills]);

  const openModal = (module) => {
    setCurrentModule(module);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentModule(null);
  };

  const handleDrillSelect = (drill) => {
    if (currentModule) {
      handleDrillAssign(sessionDetails.modules.findIndex(m => m.name === currentModule.name), drill);
    }
    closeModal();
  };

  const skillTypes = useMemo(() => {
    return [...new Set(allDrills.flatMap(drill => drill.tags))];
  }, [allDrills]);

  const ageGroups = useMemo(() => {
    return [...new Set(allDrills.map(drill => drill.ageGroup))];
  }, [allDrills]);

  useEffect(() => {
    filterDrills();
  }, [searchTerm, filterSkillTypes, filterAgeGroups, sessionDrills]);

  const filterDrills = useCallback(() => {
    let filtered = allDrills;

    if (searchTerm) {
      filtered = filtered.filter(drill => 
        drill.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        drill.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (filterSkillTypes.length > 0) {
      filtered = filtered.filter(drill => 
        drill.tags.some(tag => filterSkillTypes.includes(tag))
      );
    }

    if (filterAgeGroups.length > 0) {
      filtered = filtered.filter(drill => filterAgeGroups.includes(drill.ageGroup));
    }

    setAvailableDrills(filtered.filter(drill => !sessionDrills.some(sd => sd._id === drill._id)));
  }, [allDrills, searchTerm, filterSkillTypes, filterAgeGroups, sessionDrills]);

  const moveDrill = useCallback((dragIndex, hoverIndex) => {
    setSessionDrills((prevDrills) => {
      const newDrills = [...prevDrills];
      const [reorderedItem] = newDrills.splice(dragIndex, 1);
      newDrills.splice(hoverIndex, 0, reorderedItem);
      return newDrills;
    });
  }, []);

  const handleDrop = useCallback((item) => {
    const drillToMove = allDrills.find((d) => d._id === item.id);
    if (drillToMove && !item.isSessionDrill) {
      setSessionDrills((prevSessionDrills) => [...prevSessionDrills, drillToMove]);
      setAvailableDrills((prevAvailableDrills) => 
        prevAvailableDrills.filter((d) => d._id !== item.id)
      );
    }
  }, [allDrills]);

  const [, drop] = useDrop({
    accept: 'DRILL',
    drop: handleDrop,
  });

  const removeDrill = (drillId) => {
    const drillToRemove = sessionDrills.find((d) => d._id === drillId);
    if (drillToRemove) {
      setSessionDrills((prevSessionDrills) => 
        prevSessionDrills.filter((d) => d._id !== drillId)
      );
      setAvailableDrills((prevAvailableDrills) => [...prevAvailableDrills, drillToRemove]);
    }
  };

  const handleTemplateSelect = (templateId) => {
    const template = templates.find(t => t._id === templateId);
    setSelectedTemplate(template);
    if (template) {
      setSessionDetails(prev => ({
        ...prev,
        modules: template.modules.map(module => ({
          ...module,
          drill: null
        }))
      }));
    } else {
      setSessionDetails(prev => ({ ...prev, modules: [] }));
    }
  };

  const handleDrillAssign = (moduleIndex, drill) => {
    setSessionDetails(prev => {
      const updatedModules = [...prev.modules];
      updatedModules[moduleIndex].drill = drill;
      return { ...prev, modules: updatedModules };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const sessionDataToSend = {
        date: sessionDetails.date,
        time: sessionDetails.time,
        location: sessionDetails.location,
        notes: sessionDetails.notes,
        drills: selectedTemplate
          ? sessionDetails.modules.filter(module => module.drill).map(module => module.drill._id)
          : sessionDrills.map(drill => drill._id)
      };
      console.log('Session data being sent:', sessionDataToSend);
      const newSession = await createPracticeSession(sessionDataToSend);
      console.log('New session created:', newSession);
      navigate(`/sessions/${newSession._id}`);
    } catch (error) {
      console.error("Error creating practice session:", error);
      setError('Failed to create practice session. Please try again.');
    }
  };

  const matchesSuggestedSkills = useCallback((drill, module) => {
    console.log(`Checking drill: ${drill.title}`);
    console.log(`Drill tags: ${drill.tags.join(', ')}`);
    console.log(`Drill duration: ${drill.duration} minutes`);
    console.log(`Module suggested skills: ${module.suggestedSkills.join(', ')}`);
    console.log(`Module duration: ${module.duration} minutes`);

    const drillSkills = drill.tags.map(tag => tag.toLowerCase());

    const skillMatch = module.suggestedSkills.some(suggestedSkill => 
      drillSkills.some(drillSkill => {
        const isMatch = suggestedSkill.toLowerCase().includes(drillSkill);
        console.log(`Checking if ${drillSkill} includes ${suggestedSkill}: ${isMatch}`);
        return isMatch;
      })
    );

    // Time-based filtering
    const timeMatch = Math.abs(drill.duration - module.duration) <= 5;
    console.log(`Time match: ${timeMatch}`);

    const overallMatch = skillMatch && timeMatch;
    console.log(`Overall match result: ${overallMatch}`);
    return overallMatch;
  }, []);

  const filteredDrillsForModule = useCallback((module) => {
    console.log(`Filtering drills for module: ${module.name}`);
    const filteredDrills = allDrills.filter(drill => matchesSuggestedSkills(drill, module));
    console.log(`Found ${filteredDrills.length} matching drills`);
    return filteredDrills;
  }, [allDrills, matchesSuggestedSkills]);

  const handleAddDrill = useCallback(async (drill) => {
    setSessionDrills((prevSessionDrills) => [...prevSessionDrills, drill]);
    setAvailableDrills((prevAvailableDrills) => 
      prevAvailableDrills.filter((d) => d._id !== drill._id)
    );
    try {
      await incrementDrillUsage(drill._id);
    } catch (error) {
      console.error('Failed to increment drill usage:', error);
    }
  }, []);

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const renderDrillSections = () => (
    <>
      <div className="md:flex-1">
        <h3 className="font-semibold text-lg mb-2 text-secondary md:hidden" onClick={() => setShowAvailableDrills(!showAvailableDrills)}>
          Available Drills {showAvailableDrills ? <ChevronUpIcon className="inline h-5 w-5" /> : <ChevronDownIcon className="inline h-5 w-5" />}
        </h3>
        <h3 className="font-semibold text-lg mb-2 text-secondary hidden md:block">Available Drills</h3>
        <div className={`space-y-2 mb-4 ${showAvailableDrills ? '' : 'hidden md:block'}`}>
          <input
            type="text"
            placeholder="Search drills..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-primary focus:border-primary"
          />
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Sort by</label>
            <select
              value={sortBy}
              onChange={handleSortChange}
              className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-primary focus:border-primary"
            >
              <option value="default">Default</option>
              <option value="popular">Most Popular</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Skill Types</label>
            <MultiSelect
              options={skillTypes}
              value={filterSkillTypes}
              onChange={setFilterSkillTypes}
              placeholder="Select skill types"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Age Groups</label>
            <MultiSelect
              options={ageGroups}
              value={filterAgeGroups}
              onChange={setFilterAgeGroups}
              placeholder="Select age groups"
            />
          </div>
        </div>
        <div className={`bg-gray-100 p-4 rounded-md h-[400px] overflow-y-auto ${showAvailableDrills ? '' : 'hidden md:block'}`}>
          {availableDrills.map((drill) => (
            <DrillItem key={drill._id} drill={drill} isSessionDrill={false} onAdd={handleAddDrill} />
          ))}
        </div>
      </div>
      <div className="md:flex-1 mt-4 md:mt-0 md:ml-4">
        <h3 className="font-semibold text-lg mb-2 text-secondary md:hidden" onClick={() => setShowSessionDrills(!showSessionDrills)}>
          Session Drills {showSessionDrills ? <ChevronUpIcon className="inline h-5 w-5" /> : <ChevronDownIcon className="inline h-5 w-5" />}
        </h3>
        <h3 className="font-semibold text-lg mb-2 text-secondary hidden md:block">Session Drills</h3>
        <div 
          ref={drop}
          className={`bg-gray-100 p-4 rounded-md h-[400px] md:h-[683px] overflow-y-auto ${showSessionDrills ? '' : 'hidden md:block'}`}
        >
          {sessionDrills.map((drill, index) => (
            <DrillItem 
              key={drill._id} 
              drill={drill} 
              index={index} 
              moveDrill={moveDrill}
              removeDrill={removeDrill}
              isSessionDrill={true}
            />
          ))}
        </div>
      </div>
    </>
  );

  return (
    <div className="max-w-6xl mx-auto p-4 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-primary">Create Practice Session</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">Date</label>
            <input
              id="date"
              type="date"
              value={sessionDetails.date}
              onChange={(e) => setSessionDetails({ ...sessionDetails, date: e.target.value })}
              required
              className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-primary focus:border-primary"
            />
          </div>
          <div>
            <label htmlFor="time" className="block text-sm font-medium text-gray-700 mb-1">Time</label>
            <input
              id="time"
              type="time"
              value={sessionDetails.time}
              onChange={(e) => setSessionDetails({ ...sessionDetails, time: e.target.value })}
              required
              className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-primary focus:border-primary"
            />
          </div>
        </div>
        <div>
          <label htmlFor="location" className="block text-sm font-medium text-gray-700 mb-1">Location</label>
          <input
            id="location"
            type="text"
            value={sessionDetails.location}
            onChange={(e) => setSessionDetails({ ...sessionDetails, location: e.target.value })}
            placeholder="Enter session location"
            required
            className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-primary focus:border-primary"
          />
        </div>
        <div>
          <label htmlFor="notes" className="block text-sm font-medium text-gray-700 mb-1">Session Notes</label>
          <textarea
            id="notes"
            value={sessionDetails.notes}
            onChange={(e) => setSessionDetails({ ...sessionDetails, notes: e.target.value })}
            placeholder="Enter any additional notes for the session"
            className="w-full border border-gray-300 rounded-md shadow-sm p-2 h-24 focus:ring-primary focus:border-primary"
          />
        </div>
        
        <div>
          <label htmlFor="template" className="flex items-center text-sm font-medium text-gray-700 mb-1">
            <SparklesIcon className="h-5 w-5 text-yellow-400 mr-2" />
            Session Template (Premium Feature)
          </label>
          <select
            id="template"
            value={selectedTemplate ? selectedTemplate._id : ''}
            onChange={(e) => handleTemplateSelect(e.target.value)}
            className="w-full border border-yellow-300 bg-yellow-50 rounded-md shadow-sm p-2 focus:ring-primary focus:border-primary"
          >
            <option value="">Select a template</option>
            {templates.map((template) => (
              <option key={template._id} value={template._id}>{template.name}</option>
            ))}
          </select>
        </div>
        
        {selectedTemplate && (
          <SessionTemplateOverview 
            modules={sessionDetails.modules}
            onModuleClick={openModal}
            filteredDrillsForModule={filteredDrillsForModule}
            isInPracticeBuilder={true}
          />
        )}
        
        {!selectedTemplate && (
          <div className="md:flex md:space-x-4">
            {renderDrillSections()}
          </div>
        )}
        
        <button 
          type="submit" 
          className="w-full bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out"
        >
          Create Session
        </button>
      </form>
      <DrillSelectionModal
        isOpen={isModalOpen}
        onClose={closeModal}
        module={currentModule}
        drills={currentModule ? filteredDrillsForModule(currentModule) : []}
        onSelectDrill={handleDrillSelect}
      />
    </div>
  );
};

export default PracticeSessionBuilder;