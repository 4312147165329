import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import api from '../services/api';
import { PlusCircleIcon, UserIcon, XCircleIcon } from '@heroicons/react/24/outline';

const UserProfileManagement = () => {
    const { user, userProfile, loadUserProfile } = useAuth();
    const [availablePlayers, setAvailablePlayers] = useState([]);
    const [selectedPlayer, setSelectedPlayer] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchAvailablePlayers();
    }, [userProfile]);

    const fetchAvailablePlayers = async () => {
        try {
            setIsLoading(true);
            const response = await api.get('/players');
            const allPlayers = response.data;
            
            // Filter out players that are already linked
            const linkedPlayerIds = userProfile?.linkedPlayers.map(player => player._id) || [];
            const unlinkedPlayers = allPlayers.filter(player => 
                !linkedPlayerIds.includes(player._id) && player.user !== user.id
            );
            
            setAvailablePlayers(unlinkedPlayers);
        } catch (error) {
            console.error('Error fetching available players:', error);
            setError('Failed to fetch available players');
        } finally {
            setIsLoading(false);
        }
    };

    const handleLinkPlayer = async () => {
        if (!selectedPlayer) return;
        try {
            setIsLoading(true);
            await api.post('/user-profile/link-player', { playerId: selectedPlayer });
            await loadUserProfile();
            setSelectedPlayer('');
            setError('');
        } catch (error) {
            console.error('Error linking player:', error);
            setError('Failed to link player');
        } finally {
            setIsLoading(false);
        }
    };

    const handleUnlinkPlayer = async (playerId) => {
        try {
            setIsLoading(true);
            await api.post('/user-profile/unlink-player', { playerId });
            await loadUserProfile();
            setError('');
        } catch (error) {
            console.error('Error unlinking player:', error);
            setError('Failed to unlink player');
        } finally {
            setIsLoading(false);
        }
    };

    const linkedPlayers = userProfile?.linkedPlayers.filter(player => player.user !== user._id) || [];

    return (
        <div className="max-w-4xl mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6 text-primary">Manage Linked Players</h1>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            
            <div className="bg-white shadow-md rounded-lg p-6 mb-6">
                <h2 className="text-xl font-semibold mb-4 text-secondary">Linked Players</h2>
                {linkedPlayers.length === 0 ? (
                    <p className="text-gray-500">No other players linked yet.</p>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                        {linkedPlayers.map(player => (
                            <div key={player._id} className="bg-gray-100 rounded-lg p-4 flex items-center justify-between">
                                <div className="flex items-center">
                                    <UserIcon className="h-8 w-8 text-primary mr-2" />
                                    <span className="font-medium">{player.name}</span>
                                </div>
                                <button
                                    onClick={() => handleUnlinkPlayer(player._id)}
                                    className="text-red-500 hover:text-red-700 transition-colors duration-200"
                                    disabled={isLoading}
                                >
                                    <XCircleIcon className="h-6 w-6" />
                                    {isLoading && 'Unlinking...'}
                                </button>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            
            <div className="bg-white shadow-md rounded-lg p-6">
                <h2 className="text-xl font-semibold mb-4 text-secondary">Link New Player</h2>
                <div className="flex items-center space-x-2">
                    <select
                        value={selectedPlayer}
                        onChange={(e) => setSelectedPlayer(e.target.value)}
                        className="flex-grow border border-gray-300 rounded-md shadow-sm p-2 focus:ring-primary focus:border-primary"
                        disabled={isLoading || availablePlayers.length === 0}
                    >
                        <option value="">Select a player</option>
                        {availablePlayers.map(player => (
                            <option key={player._id} value={player._id}>{player.name}</option>
                        ))}
                    </select>
                    <button
                        onClick={handleLinkPlayer}
                        className="bg-primary text-white px-4 py-2 rounded-md hover:bg-primary-dark transition-colors duration-200 flex items-center"
                        disabled={isLoading || !selectedPlayer}
                    >
                        <PlusCircleIcon className="h-5 w-5 mr-2" />
                        {isLoading ? 'Linking...' : 'Link Player'}
                    </button>
                </div>
                {availablePlayers.length === 0 && (
                    <p className="text-gray-500 mt-4">No available players to link.</p>
                )}
            </div>
        </div>
    );
};

export default UserProfileManagement;