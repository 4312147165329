import React from 'react';
import SessionTemplateOverview from './SessionTemplateOverview';

const SessionTemplateDetails = ({ template, onClose, onSelect }) => {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-2xl w-full m-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-primary">{template.name}</h2>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        
        <SessionTemplateOverview modules={template.modules} />
        
        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
          >
            Close
          </button>
          {/* <button
            onClick={() => onSelect(template)}
            className="px-4 py-2 bg-primary text-white rounded hover:bg-primary-dark"
          >
            Use This Template
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default SessionTemplateDetails;