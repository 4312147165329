import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { Home, Clipboard, Users, Calendar, LogOut, Menu, X, List, User, FileText, Settings, ChevronDown } from 'lucide-react';

const Navigation = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const navItems = user && user.role === 'coach' ? [
    { name: 'Rosters', path: '/rosters', icon: <List size={20} /> },
    { name: 'Drills', path: '/drills', icon: <Clipboard size={20} /> },    
    { name: 'Sessions', path: '/sessions', icon: <Calendar size={20} /> },
    { name: 'Session Templates', path: '/session-templates', icon: <FileText size={20} /> },    
  ] : [
    { name: 'Drills', path: '/drills', icon: <Clipboard size={20} /> },
    { name: 'My Profile', path: '/player-profile', icon: <User size={20} /> },
  ];

  const NavLink = ({ item }) => (
    <Link
      to={item.path}
      className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${
        location.pathname === item.path
          ? 'bg-primary text-white'
          : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900'
      }`}
    >
      {item.icon}
      <span className="ml-3">{item.name}</span>
    </Link>
  );

  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
              {user && navItems.map((item) => (
                <NavLink key={item.name} item={item} />
              ))}
            </div>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            {user && (
              <>
                <span className="mr-4 text-sm font-medium text-gray-600 bg-gray-200 px-2 py-1 rounded-full">
                  {user.role === 'coach' ? 'Coach' : 'Player'}
                </span>
                <div className="relative">
                  <button
                    onClick={() => setIsSettingsOpen(!isSettingsOpen)}
                    className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    <Settings size={20} />
                    <span className="ml-2">Settings</span>
                    <ChevronDown size={16} className="ml-1" />
                  </button>
                  {isSettingsOpen && (
                    <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Link
                        to="/user-profile"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => setIsSettingsOpen(false)}
                      >
                        <div className="flex items-center">
                          <Users size={20} />
                          <span className="ml-2">Manage Players</span>
                        </div>
                      </Link>
                      <button
                        onClick={() => {
                          setIsSettingsOpen(false);
                          handleLogout();
                        }}
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        <div className="flex items-center">
                          <LogOut size={20} />
                          <span className="ml-2">Logout</span>
                        </div>
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}
            {!user && (
              <Link
                to="/login"
                className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900 rounded-md"
              >
                Login
              </Link>
            )}
          </div>
          <div className="-mr-2 flex items-center sm:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
            >
              <span className="sr-only">Open main menu</span>
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="sm:hidden">
          <div className="pt-2 pb-3 space-y-1">
            {user && navItems.map((item) => (
              <NavLink key={item.name} item={item} />
            ))}
            {user && (
              <>
                <Link
                  to="/user-profile"
                  className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                >
                  <Users size={20} />
                  <span className="ml-3">Manage Players</span>
                </Link>
                <button
                  onClick={handleLogout}
                  className="flex items-center w-full px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                >
                  <LogOut size={20} />
                  <span className="ml-3">Logout</span>
                </button>
              </>
            )}
            {!user && (
              <Link
                to="/login"
                className="flex items-center w-full px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                Login
              </Link>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navigation;