import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { createSessionTemplate, getSessionTemplateById, updateSessionTemplate } from '../services/sessionTemplatesService';

const allowedSkills = [
    'Dribbling',
    'Passing',
    'Shooting',
    'Defending',
    'Warm Up',
    'Ball Control',
    'Heading',
    'Tackling',
    'Positioning',
    'Speed',
    'Stamina',
    'Strength',
    'Agility',
    'Teamwork',
    'Communication'
  ];

function SessionTemplateForm() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [template, setTemplate] = useState({
        name: '',
        description: '',
        ageGroup: '',
        skillLevel: '',
        totalDuration: 0,
        modules: []
    });
    const [loading, setLoading] = useState(id ? true : false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (id) {
            const fetchTemplate = async () => {
                try {
                    const data = await getSessionTemplateById(id);
                    setTemplate(data);
                    setLoading(false);
                } catch (err) {
                    setError('Failed to fetch template');
                    setLoading(false);
                }
            };
            fetchTemplate();
        }
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTemplate(prev => ({ ...prev, [name]: value }));
    };

    const handleModuleChange = (index, field, value) => {
        const updatedModules = [...template.modules];
        updatedModules[index] = { ...updatedModules[index], [field]: value };
        setTemplate(prev => ({ ...prev, modules: updatedModules }));
    };

    const handleSkillToggle = (index, skill) => {
        const updatedModules = [...template.modules];
        const currentSkills = updatedModules[index].suggestedSkills || [];
        const newSkills = currentSkills.includes(skill)
            ? currentSkills.filter(s => s !== skill)
            : [...currentSkills, skill];
        updatedModules[index] = { ...updatedModules[index], suggestedSkills: newSkills };
        setTemplate(prev => ({ ...prev, modules: updatedModules }));
    };

    const addModule = () => {
        setTemplate(prev => ({
            ...prev,
            modules: [...prev.modules, { name: '', duration: 0, suggestedSkills: [], description: '' }]
        }));
    };

    const removeModule = (index) => {
        const updatedModules = template.modules.filter((_, i) => i !== index);
        setTemplate(prev => ({ ...prev, modules: updatedModules }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (id) {
                await updateSessionTemplate(id, template);
            } else {
                await createSessionTemplate(template);
            }
            navigate('/session-templates');
        } catch (err) {
            setError('Failed to save template');
        }
    };

    if (loading) return (
        <div className="max-w-4xl mx-auto p-6">
          <div className="animate-pulse space-y-4">
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            <div className="h-10 bg-gray-200 rounded w-1/2"></div>
            <div className="space-y-2">
              {[...Array(5)].map((_, index) => (
                <div key={index} className="h-20 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
      );
    if (error) return <div>{error}</div>;

    return (
        <div className="max-w-2xl mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6 text-primary">{id ? 'Edit' : 'Create'} Session Template</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block mb-1">Template Name</label>
                    <input type="text" name="name" value={template.name} onChange={handleChange} required className="w-full p-2 border rounded" />
                </div>
                <div>
                    <label className="block mb-1">Description</label>
                    <textarea name="description" value={template.description} onChange={handleChange} className="w-full p-2 border rounded" />
                </div>
                <div>
                    <label className="block mb-1">Age Group</label>
                    <input type="text" name="ageGroup" value={template.ageGroup} onChange={handleChange} required className="w-full p-2 border rounded" />
                </div>
                <div>
                    <label className="block mb-1">Skill Level</label>
                    <input type="text" name="skillLevel" value={template.skillLevel} onChange={handleChange} required className="w-full p-2 border rounded" />
                </div>
                <div>
                <label className="block mb-1">Total Duration (minutes)</label>
                    <input type="number" name="totalDuration" value={template.totalDuration} onChange={handleChange} required className="w-full p-2 border rounded" />
                </div>
                <div>
                    <h2 className="text-xl font-semibold mb-2">Segments</h2>
                    {template.modules.map((module, index) => (
                        <div key={index} className="border-2 border-secondary p-4 mb-4 rounded-lg shadow-md bg-background">
                            <div className="mb-2">
                                <label className="block mb-1 font-semibold text-secondary">Segment Name</label>
                                <input
                                    type="text"
                                    value={module.name}
                                    onChange={(e) => handleModuleChange(index, 'name', e.target.value)}
                                    required
                                    className="w-full p-2 border-2 border-gray-300 rounded focus:border-primary focus:ring-1 focus:ring-primary"
                                />
                            </div>
                            <div className="mb-2">
                                <label className="block mb-1 font-semibold text-secondary">Duration (minutes)</label>
                                <input
                                    type="number"
                                    value={module.duration}
                                    onChange={(e) => handleModuleChange(index, 'duration', parseInt(e.target.value))}
                                    required
                                    className="w-full p-2 border-2 border-gray-300 rounded focus:border-primary focus:ring-1 focus:ring-primary"
                                />
                            </div>
                            <div className="mb-2">
                                <label className="block mb-1 font-semibold text-secondary">Suggested Skills</label>
                                <div className="flex flex-wrap gap-2">
                                    {allowedSkills.map(skill => (
                                        <button
                                            key={skill}
                                            type="button"
                                            onClick={() => handleSkillToggle(index, skill)}
                                            className={`px-3 py-1 rounded-full text-sm font-semibold ${
                                                module.suggestedSkills.includes(skill)
                                                ? 'bg-primary text-white'
                                                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                                            } transition-colors duration-200`}
                                        >
                                            {skill}
                                        </button>
                                    ))}
                                </div>
                            </div>
                            <div className="mb-2">
                                <label className="block mb-1 font-semibold text-secondary">Description</label>
                                <textarea
                                    value={module.description}
                                    onChange={(e) => handleModuleChange(index, 'description', e.target.value)}
                                    className="w-full p-2 border-2 border-gray-300 rounded focus:border-primary focus:ring-1 focus:ring-primary"
                                />
                            </div>
                            <button
                                type="button"
                                onClick={() => removeModule(index)}
                                className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 transition duration-300"
                            >
                                Remove Segment
                            </button>
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={addModule}
                        className="bg-secondary text-white px-4 py-2 rounded hover:bg-secondary-dark transition duration-300"
                    >
                        Add Segment
                    </button>
                </div>
                <button type="submit" className="bg-primary text-secondary px-4 py-2 rounded hover:bg-primary-dark transition duration-300 font-bold">
                    {id ? 'Update' : 'Create'} Template
                </button>
            </form>
        </div>
    );
}

export default SessionTemplateForm;