import api from './api';

export const getDrills = async (page, pageSize) => {
    try {
        let url = '/drills';
        if (page && pageSize) {
            url += `?page=${page}&pageSize=${pageSize}`;
        }
        const response = await api.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching drills:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const getPopularDrills = async (page, pageSize) => {
    try {
      const response = await api.get(`/drills/popular?page=${page}&pageSize=${pageSize}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching popular drills:', error);
      throw error;
    }
  };
  
export const incrementDrillUsage = async (drillId) => {
try {
    const response = await api.post(`/drills/${drillId}/incrementUsage`);
    return response.data;
} catch (error) {
    console.error('Error incrementing drill usage:', error);
    throw error;
}
};

export const getDrillById = async (id) => {
    try {
        const response = await api.get(`/drills/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching drill:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const createDrill = async (drill) => {
    try {
        const response = await api.post('/drills', drill);
        return response.data;
    } catch (error) {
        console.error('Error creating drill:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const updateDrill = async (id, drill) => {
    try {
        console.log('Sending drill data:', drill);
        const response = await api.put(`/drills/${id}`, drill);
        return response.data;
    } catch (error) {
        console.error('Error updating drill:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const deleteDrill = async (id) => {
    try {
        const response = await api.delete(`/drills/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting drill:', error.response ? error.response.data : error.message);
        throw error;
    }
};