import api from './api';

export const createRoster = async (rosterData) => {
  try {
    const response = await api.post('/rosters', rosterData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getRosters = async () => {
  try {
    const response = await api.get('/rosters');
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getRosterById = async (id) => {
  try {
    const response = await api.get(`/rosters/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateRoster = async (id, rosterData) => {
  try {
    const response = await api.put(`/rosters/${id}`, rosterData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteRoster = async (id) => {
  try {
    const response = await api.delete(`/rosters/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const addPlayerToRoster = async (rosterId, playerId) => {
  try {
    const response = await api.post(`rosters/${rosterId}/players`, { playerId });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const removePlayerFromRoster = async (rosterId, playerId) => {
  try {
    const response = await api.delete(`rosters/${rosterId}/players/${playerId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};