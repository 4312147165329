import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getRosterById, deleteRoster } from '../services/rostersService';
import { useAuth } from './AuthContext';
import { UserIcon } from '@heroicons/react/24/solid';
import InvitePlayer from './InvitePlayer';

function RosterDetail() {
    const [roster, setRoster] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const { user } = useAuth();

    useEffect(() => {
        const fetchRoster = async () => {
            try {
                const data = await getRosterById(id);
                setRoster(data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch roster details');
                setLoading(false);
            }
        };

        fetchRoster();
    }, [id]);

    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this roster?')) {
            try {
                await deleteRoster(id);
                navigate('/rosters');
            } catch (err) {
                setError('Failed to delete roster');
            }
        }
    };

    if (loading) return (
        <div className="max-w-4xl mx-auto p-6">
          <div className="animate-pulse space-y-4">
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            <div className="h-10 bg-gray-200 rounded w-1/2"></div>
            <div className="space-y-2">
              {[...Array(5)].map((_, index) => (
                <div key={index} className="h-20 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
      );
    if (error) return <div>Error: {error}</div>;
    if (!roster) return <div>Roster not found</div>;

    const isCoach = user.role === 'coach';
    const isOwner = isCoach && user._id === roster.owner;
    const isPlayer = user.role === 'player';

    return (
        <div className="max-w-4xl mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6 text-primary">{roster.name}</h1>
            <div className="bg-white shadow-md rounded-lg p-6 mb-6">
                <div className="grid grid-cols-2 gap-4 mb-6">
                    <div>
                        <p className="text-gray-600 font-semibold">Season</p>
                        <p className="text-lg">{roster.season}</p>
                    </div>
                    <div>
                        <p className="text-gray-600 font-semibold">Age Group</p>
                        <p className="text-lg">{roster.ageGroup}</p>
                    </div>
                </div>
                <h2 className="text-2xl font-semibold mb-4">Players</h2>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                    {roster.players.map(player => (
                        <div key={player._id} className="bg-gray-100 rounded-lg p-4 flex flex-col items-center justify-between h-48 hover:bg-gray-200 transition duration-300 ease-in-out">
                            <Link to={`/players/${player._id}`} className="flex flex-col items-center">
                                <UserIcon className="h-12 w-12 text-primary mb-2" />
                                <p className="font-medium text-center">{player.name}</p>
                                {player.jerseyNumber && <p className="text-sm text-gray-600">#{player.jerseyNumber}</p>}
                            </Link>
                            {isCoach && isOwner && !player.user && (
                                <InvitePlayer playerId={player._id} />
                            )}                            
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex justify-between">
                {isCoach && isOwner && (
                    <>
                        <Link 
                            to={`/edit-roster/${roster._id}`} 
                            className="bg-secondary hover:bg-secondary-dark text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                        >
                            Edit Roster
                        </Link>
                        <button 
                            onClick={handleDelete}
                            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                        >
                            Delete Roster
                        </button>
                    </>
                )}
                {isPlayer && (
                    <Link 
                        to="/player-profile" 
                        className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                    >
                        Back to Profile
                    </Link>
                )}
            </div>
        </div>
    );
}

export default RosterDetail;