import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getRosters, deleteRoster } from '../services/rostersService';
import { TrashIcon, PencilIcon } from '@heroicons/react/24/outline';
import { useAuth } from './AuthContext';

function RosterList() {
    const [rosters, setRosters] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user } = useAuth();

    useEffect(() => {
        const fetchRosters = async () => {
            try {
                const data = await getRosters();
                setRosters(data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch rosters');
                setLoading(false);
            }
        };

        fetchRosters();
    }, []);

    const handleDelete = async (e, id) => {
        e.preventDefault(); // Prevent the link from being followed
        e.stopPropagation(); // Prevent the event from bubbling up
        if (window.confirm('Are you sure you want to delete this roster?')) {
            try {
                await deleteRoster(id);
                setRosters(rosters.filter(roster => roster._id !== id));
            } catch (err) {
                setError('Failed to delete roster');
            }
        }
    };

    if (loading) return (
        <div className="max-w-4xl mx-auto p-6">
          <div className="animate-pulse space-y-4">
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            <div className="h-10 bg-gray-200 rounded w-1/2"></div>
            <div className="space-y-2">
              {[...Array(5)].map((_, index) => (
                <div key={index} className="h-20 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
      );
    if (error) return <div>{error}</div>;

    return (
        <div className="max-w-4xl mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6 text-primary">Your Rosters</h1>
            <Link 
                to="/create-roster" 
                className="inline-block mb-6 bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
            >
                Create New Roster
            </Link>
            {rosters.length === 0 ? (
                <p className="text-gray-600 text-lg">No rosters found.</p>
            ) : (
                <ul className="space-y-4">
                    {rosters.map((roster) => (
                        <li key={roster._id} className="bg-white shadow-md rounded-lg p-6 hover:shadow-lg transition duration-300 ease-in-out">
                            <Link to={`/rosters/${roster._id}`} className="block">
                                <h2 className="text-xl font-semibold text-primary mb-2">{roster.name}</h2>
                                <p className="text-gray-600">Season: {roster.season}</p>
                                <p className="text-gray-600">Age Group: {roster.ageGroup}</p>
                                <p className="text-gray-600">Players: {roster.players.length}</p>
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default RosterList;