import React, { useEffect, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { getDrillById } from '../services/drillsService';

function DrillDetails() {
    const { id } = useParams();
    const [drill, setDrill] = useState(null);
    const [error, setError] = useState(null);
    const location = useLocation();

    useEffect(() => {
        async function fetchDrill() {
            try {
                const data = await getDrillById(id);
                setDrill(data);
            } catch (err) {
                setError(err.message);
            }
        }

        fetchDrill();
    }, [id]);

    const getYouTubeEmbedUrl = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        if (match && match[2].length === 11) {
            return `https://www.youtube.com/embed/${match[2]}`;
        }
        return null;
    };

    if (error) return <div>Error: {error}</div>;
    if (!drill) return (
        <div className="max-w-4xl mx-auto p-6">
          <div className="animate-pulse space-y-4">
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            <div className="h-10 bg-gray-200 rounded w-1/2"></div>
            <div className="space-y-2">
              {[...Array(5)].map((_, index) => (
                <div key={index} className="h-20 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
    );

    const embedUrl = drill.videoUrl ? getYouTubeEmbedUrl(drill.videoUrl) : null;

    // Check if we have a sessionId in the URL parameters
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('sessionId');

    return (
        <div className="max-w-2xl mx-auto p-6 bg-white shadow-lg rounded-lg">
            <h1 className="text-3xl font-bold mb-4 text-primary">{drill.title}</h1>
            <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2 text-secondary">Description</h2>
                <p className="text-gray-700">{drill.description}</p>
            </div>
            {embedUrl && (
                <div className="mb-6">
                    <h2 className="text-xl font-semibold mb-2 text-secondary">Video Demonstration</h2>
                    <div className="relative" style={{ paddingBottom: '56.25%', height: 0 }}>
                        <iframe
                            src={embedUrl}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded drill video"
                            className="absolute top-0 left-0 w-full h-full"
                        ></iframe>
                    </div>
                </div>
            )}
            <div className="grid grid-cols-2 gap-4 mb-6">
                <div>
                    <h3 className="font-semibold text-secondary">Age Group</h3>
                    <p className="text-gray-700">{drill.ageGroup}</p>
                </div>
                <div>
                    <h3 className="font-semibold text-secondary">Difficulty</h3>
                    <p className="text-gray-700">{drill.difficulty}</p>
                </div>
                <div>
                    <h3 className="font-semibold text-secondary">Duration</h3>
                    <p className="text-gray-700">{drill.duration} minutes</p>
                </div>
            </div>
            <div className="mb-6">
                <h3 className="font-semibold text-secondary mb-2">Skills</h3>
                <div className="flex flex-wrap gap-2">
                    {drill.tags.map((skill, index) => (
                        <span key={index} className="bg-primary text-white px-3 py-1 rounded-full text-sm font-semibold">
                            {skill}
                        </span>
                    ))}
                </div>
            </div>
            {drill.equipment.length > 0 && (
                <div className="mb-6">
                    <h3 className="font-semibold text-secondary mb-2">Equipment</h3>
                    <ul className="list-disc list-inside text-gray-700">
                        {drill.equipment.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
            )}
            <div className="flex justify-between">
                {sessionId && (
                    <Link 
                        to={`/sessions/${sessionId}`}
                        className="bg-secondary hover:bg-secondary-dark text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                    >
                        Back to Session
                    </Link>
                )}
                <Link 
                    to={`/update-drill/${drill._id}`}
                    className="bg-secondary hover:bg-secondary-dark text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                >
                    Update Drill
                </Link>
                <Link 
                    to="/"
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                >
                    Back to Drills
                </Link>
            </div>
        </div>
    );
}

export default DrillDetails;