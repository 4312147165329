import React, { useState } from 'react';
import api from '../services/api';

function InvitePlayer({ playerId }) {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState('');

    const handleInvite = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setStatus('');

        try {
            await api.post(`/players/invite/${playerId}`, { email });
            setStatus('success');
            setEmail('');
        } catch (err) {
            setStatus('error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleInvite} className="w-full mt-2">
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Player's email"
                required
                className="w-full px-2 py-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-primary focus:border-primary"
            />
            <button
                type="submit"
                disabled={isLoading}
                className="w-full mt-1 bg-primary hover:bg-primary-dark text-white font-bold py-1 px-2 text-sm rounded transition duration-300 ease-in-out disabled:opacity-50"
            >
                {isLoading ? 'Sending...' : 'Send Invite'}
            </button>
            {status === 'success' && <p className="text-green-500 text-xs mt-1">Invite sent!</p>}
            {status === 'error' && <p className="text-red-500 text-xs mt-1">Failed to send</p>}
        </form>
    );
}

export default InvitePlayer;