import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getSessionTemplates } from '../services/sessionTemplatesService';
import SessionTemplateDetails from './SessionTemplateDetails';
import { useAuth } from './AuthContext';
import SubscriptionButton from '../components/SubscriptionButton';
import { Star, Lock } from 'lucide-react';

function SessionTemplateList() {
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const { isPremium, checkSubscriptionStatus } = useAuth();

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                await checkSubscriptionStatus(); 
                const data = await getSessionTemplates();
                setTemplates(data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch session templates');
                setLoading(false);
            }
        };

        fetchTemplates();
    }, [checkSubscriptionStatus]);

    const handleTemplateClick = (template) => {
        setSelectedTemplate(template);
    };

    const handleCloseDetails = () => {
        setSelectedTemplate(null);
    };

    if (loading) return (
        <div className="max-w-4xl mx-auto p-6">
          <div className="animate-pulse space-y-4">
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            <div className="h-10 bg-gray-200 rounded w-1/2"></div>
            <div className="space-y-2">
              {[...Array(5)].map((_, index) => (
                <div key={index} className="h-20 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
    );
    if (error) return <div className="text-red-500">{error}</div>;

    return (
        <div className="max-w-4xl mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6 text-primary">Session Templates</h1>
            {!isPremium && (
                <div className="mb-8 p-6 bg-gradient-to-r from-primary to-primary-dark rounded-lg shadow-lg text-white">
                    <div className="flex items-center mb-4">
                        <Star className="h-8 w-8 mr-2" />
                        <h2 className="text-2xl font-bold">Upgrade to Premium</h2>
                    </div>
                    <p className="mb-4 text-lg">
                        Unlock Session Templates and supercharge your coaching experience!
                    </p>
                    <ul className="mb-6 space-y-2">
                        <li className="flex items-center">
                            <Lock className="h-5 w-5 mr-2" />
                            Access to pre-designed session templates
                        </li>
                        <li className="flex items-center">
                            <Lock className="h-5 w-5 mr-2" />
                            Customizable templates for different age groups and skill levels
                        </li>
                        <li className="flex items-center">
                            <Lock className="h-5 w-5 mr-2" />
                            Save time on session planning
                        </li>
                    </ul>
                    <SubscriptionButton className="w-full bg-white text-primary hover:bg-gray-100 font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105" />
                </div>
            )}
            {isPremium && (
            <>
                <Link 
                    to="/create-session-template" 
                    className="inline-block mb-6 bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                >
                    Create New Template
                </Link>
                {templates.length === 0 ? (
                    <p className="text-gray-600 text-lg">No session templates found. Create your first template!</p>
                ) : (
                    <ul className="space-y-4">
                        {templates.map((template) => (
                            <li 
                                key={template._id} 
                                className="bg-white shadow-md rounded-lg p-6 hover:shadow-lg transition duration-300 ease-in-out cursor-pointer"
                                onClick={() => handleTemplateClick(template)}
                            >
                                <h2 className="text-xl font-semibold text-primary mb-2">{template.name}</h2>
                                <p className="text-gray-600">Total Duration: {template.modules.reduce((sum, module) => sum + module.duration, 0)} minutes</p>
                                <p className="text-gray-600">Modules: {template.modules.length}</p>
                            </li>
                        ))}
                    </ul>
                )}
                {selectedTemplate && (
                    <SessionTemplateDetails 
                        template={selectedTemplate}
                        onClose={handleCloseDetails}
                    />
                )}
            </>
            )}
        </div>
    );
}

export default SessionTemplateList;