import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getPlayerById, getPlayerByUserId, getRostersForPlayer, checkCoachRights } from '../services/playersService';
import { getDrillById } from '../services/drillsService';
import { PencilIcon } from '@heroicons/react/24/outline';
import { useAuth } from './AuthContext';
import { ChevronRightIcon } from 'lucide-react';
import api from '../services/api';

function PlayerProfile() {
    const [players, setPlayers] = useState([]);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [rosters, setRosters] = useState([]);
    const [hasCoachRights, setHasCoachRights] = useState(false);
    const [assignedDrills, setAssignedDrills] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user } = useAuth();
    const { id } = useParams(); // Get the player ID from URL params

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("Fetching player data. User role:", user?.role, "User ID:", user?._id);
                console.log(user);
                let playerData;

                if (id) {
                    // If we have an ID in the URL, fetch that specific player
                    playerData = await getPlayerById(id);
                    setPlayers([playerData]);
                    setSelectedPlayer(playerData);
                } else if (user?._id) {
                    // If no ID in URL, fetch linked players for the logged-in user
                    const response = await api.get(`/user-profile`);
                    if (response.data.linkedPlayers && response.data.linkedPlayers.length > 0) {
                        setPlayers(response.data.linkedPlayers);
                        setSelectedPlayer(response.data.linkedPlayers[0]);
                        playerData = response.data.linkedPlayers[0];
                    } else {
                        // Fallback: fetch the player directly if no linked players
                        playerData = await getPlayerByUserId(user._id);
                        setPlayers([playerData]);
                        setSelectedPlayer(playerData);
                    }
                } else if (user.id) {
                    // If no ID in URL, fetch linked players for the logged-in user
                    const response = await api.get(`/user-profile`);
                    if (response.data.linkedPlayers && response.data.linkedPlayers.length > 0) {
                        setPlayers(response.data.linkedPlayers);
                        setSelectedPlayer(response.data.linkedPlayers[0]);
                        playerData = response.data.linkedPlayers[0];
                    } else {
                        // Fallback: fetch the player directly if no linked players
                        playerData = await getPlayerByUserId(user._id);
                        setPlayers([playerData]);
                        setSelectedPlayer(playerData);
                    }
                } else {
                    throw new Error("No user ID or player ID available");
                }

                if (playerData) {
                    const rostersData = await getRostersForPlayer(playerData._id);
                    setRosters(rostersData);

                    if (playerData.assignedDrills && playerData.assignedDrills.length > 0) {
                        const fullDrillsData = await Promise.all(
                            playerData.assignedDrills.map(drillId => 
                                typeof drillId === 'string' ? getDrillById(drillId) : drillId
                            )
                        );
                        setAssignedDrills(fullDrillsData);
                    }
                }
                
                setLoading(false);
            } catch (err) {
                console.error("Error fetching player data:", err);
                setError('Failed to load player data');
                setLoading(false);
            }
        };

        fetchData();
    }, [user, id]);

    useEffect(() => {
        const checkRights = async () => {
            if (user?.role === 'coach' && selectedPlayer?._id) {
                try {
                    const coachRights = await checkCoachRights(user._id, selectedPlayer._id);
                    setHasCoachRights(coachRights);
                } catch (err) {
                    console.error("Error checking coach rights:", err);
                    setHasCoachRights(false);
                }
            }
        };

        checkRights();
    }, [user, selectedPlayer]);

    const handlePlayerChange = async (playerId) => {
        const newSelectedPlayer = players.find(p => p._id === playerId);
        setSelectedPlayer(newSelectedPlayer);
        
        // Fetch updated data for the selected player
        try {
            const rostersData = await getRostersForPlayer(playerId);
            setRosters(rostersData);

            if (newSelectedPlayer.assignedDrills && newSelectedPlayer.assignedDrills.length > 0) {
                const fullDrillsData = await Promise.all(
                    newSelectedPlayer.assignedDrills.map(drillId => 
                        typeof drillId === 'string' ? getDrillById(drillId) : drillId
                    )
                );
                setAssignedDrills(fullDrillsData);
            } else {
                setAssignedDrills([]);
            }
        } catch (err) {
            console.error("Error fetching updated player data:", err);
            setError('Failed to load updated player data');
        }
    };

    if (loading) return (
        <div className="max-w-4xl mx-auto p-6">
          <div className="animate-pulse space-y-4">
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            <div className="h-10 bg-gray-200 rounded w-1/2"></div>
            <div className="space-y-2">
              {[...Array(5)].map((_, index) => (
                <div key={index} className="h-20 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
    );
    if (error) return <div>{error}</div>;
    if (!selectedPlayer) return <div>No player profile found</div>;

    return (
        <div className="max-w-2xl mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6 text-primary">Player Profile</h1>
            
            {players.length > 1 && (
                <div className="mb-6">
                    <label htmlFor="player-select" className="block text-sm font-medium text-gray-700">Select Player</label>
                    <select
                        id="player-select"
                        value={selectedPlayer._id}
                        onChange={(e) => handlePlayerChange(e.target.value)}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                    >
                        {players.map((player) => (
                            <option key={player._id} value={player._id}>{player.name}</option>
                        ))}
                    </select>
                </div>
            )}
            
            {/* Player Info Section */}
            <div className="bg-white shadow-md rounded-lg p-6 mb-6">
                {user.role === 'coach' && (
                    <Link 
                        to={`/edit-player/${selectedPlayer._id}`} 
                        className="absolute top-4 right-4 text-gray-500 hover:text-primary"
                        title="Edit profile"
                    >
                        <PencilIcon className="h-5 w-5" />
                    </Link>
                )}
                <p className="mb-2"><strong>Name:</strong> {selectedPlayer.name}</p>
                <p className="mb-2"><strong>Age:</strong> {selectedPlayer.age}</p>
                <p className="mb-2"><strong>Position:</strong> {selectedPlayer.position}</p>
                <p className="mb-2"><strong>Jersey Number:</strong> {selectedPlayer.jerseyNumber}</p>
                <p className="mb-2"><strong>Parent Contact:</strong> {selectedPlayer.parentContact}</p>
                <p><strong>Notes:</strong> {selectedPlayer.notes}</p>
            </div>
            
            {/* Rosters Section */}
            <div className="bg-white shadow-md rounded-lg p-6 mb-6">
                <h2 className="text-xl font-semibold mb-4 text-secondary">My Rosters</h2>
                {rosters.length === 0 ? (
                    <p>You are not part of any rosters yet.</p>
                ) : (
                    <ul className="space-y-2">
                        {rosters.map((roster) => (
                            <li key={roster._id}>
                                <Link 
                                    to={`/rosters/${roster._id}`}
                                    className="block p-2 hover:bg-gray-100 rounded transition duration-150 ease-in-out"
                                >
                                    {roster.name} - {roster.season}
                                </Link>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            
            {/* Assigned Drills Section */}
            <div className="bg-white shadow-md rounded-lg p-6 mb-6">
                <h2 className="text-xl font-semibold mb-4 text-secondary">Assigned Drills</h2>
                {assignedDrills.length === 0 ? (
                    <p>No drills assigned yet.</p>
                ) : (
                    <ul className="space-y-2">
                        {assignedDrills.map((drill) => (
                            <li key={drill._id} className="border-b pb-4">
                            <Link 
                              to={`/drills/${drill._id}`}
                              className="flex items-center justify-between hover:bg-gray-50 p-2 rounded transition duration-150 ease-in-out"
                            >
                              <div>
                                <h3 className="text-xl font-semibold text-primary">{drill.title}</h3>
                                <p className="text-gray-600">Duration: <span className="font-semibold">{drill.duration} minutes</span></p>
                                <div className="flex flex-wrap gap-1 mt-1">
                                  {drill.tags.map((skill, index) => (
                                    <span key={index} className="bg-primary text-white px-2 py-1 rounded-full text-xs">
                                      {skill}
                                    </span>
                                  ))}
                                </div>
                              </div>
                              <ChevronRightIcon className="text-gray-400" />
                            </Link>
                          </li>
                        ))}
                    </ul>
                )}
            </div>
            
            {/* Coach Actions */}
            {user.role === 'coach' && (
                <div className="flex justify-between">
                    <Link 
                        to="/players" 
                        className="bg-secondary hover:bg-secondary-dark text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                    >
                        Back to Player List
                    </Link>
                    {hasCoachRights && (
                        <Link 
                            to={`/assign-drill/${selectedPlayer._id}`}
                            className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                        >
                            Assign Drill
                        </Link>
                    )}
                </div>
            )}
        </div>
    );
}

export default PlayerProfile;