import React, { useState } from 'react';
import { createDrill } from '../services/drillsService';
import { useNavigate } from 'react-router-dom';

const allowedSkills = [
    'Dribbling',
    'Passing',
    'Shooting',
    'Defending',
    'Warm Up',
    'Ball Control',
    'Heading',
    'Tackling',
    'Positioning',
    'Speed',
    'Stamina',
    'Strength',
    'Agility',
    'Teamwork',
    'Communication'
  ];

function CreateDrill() {
    const [drill, setDrill] = useState({
            title: '',
            description: '',
            ageGroup: '',
            difficulty: '',
            duration: '',
            tags: [],
            equipment: '',
            videoUrl: ''
        });
        const [error, setError] = useState(null);
        const navigate = useNavigate();
    
        const handleChange = (e) => {
            const { name, value } = e.target;
            setDrill(prevState => ({ ...prevState, [name]: value }));
        };
    
        const handleTagToggle = (tag) => {
            setDrill(prevState => {
                const newTags = prevState.tags.includes(tag)
                    ? prevState.tags.filter(t => t !== tag)
                    : [...prevState.tags, tag];
                return { ...prevState, tags: newTags };
            });
        };
    
        const handleSubmit = async (e) => {
            e.preventDefault();
            if (drill.tags.length === 0) {
                setError('Please select at least one skill');
                return;
            }
            try {
                const newDrill = await createDrill({
                    ...drill,
                    equipment: drill.equipment.split(',').map(item => item.trim())
                });
                console.log('New drill created:', newDrill);
                navigate(`/drills/${newDrill._id}`);
            } catch (err) {
                console.error('Error creating drill:', err);
                setError(err.message || 'Failed to create drill');
            }
        };

    return (
        <div className="max-w-2xl mx-auto p-6 bg-white shadow-lg rounded-lg">
            <h1 className="text-3xl font-bold mb-6 text-primary">Create New Drill</h1>
            {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                <strong className="font-bold">Error:</strong>
                <span className="block sm:inline"> {error}</span>
            </div>}
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">Title</label>
                    <input 
                        type="text" 
                        id="title"
                        name="title" 
                        value={drill.title} 
                        onChange={handleChange} 
                        required 
                        className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-primary focus:border-primary"
                    />
                </div>
                <div>
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">Description</label>
                    <textarea 
                        id="description"
                        name="description" 
                        value={drill.description} 
                        onChange={handleChange} 
                        required 
                        className="w-full border border-gray-300 rounded-md shadow-sm p-2 h-24 focus:ring-primary focus:border-primary"
                    />
                </div>
                    <div>
                        <label htmlFor="ageGroup" className="block text-sm font-medium text-gray-700 mb-1">Age Group</label>
                        <input 
                            type="text" 
                            id="ageGroup"
                            name="ageGroup" 
                            value={drill.ageGroup} 
                            onChange={handleChange} 
                            required 
                            className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-primary focus:border-primary"
                        />
                    </div>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label htmlFor="difficulty" className="block text-sm font-medium text-gray-700 mb-1">Difficulty</label>
                        <input 
                            type="text" 
                            id="difficulty"
                            name="difficulty" 
                            value={drill.difficulty} 
                            onChange={handleChange} 
                            required 
                            className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-primary focus:border-primary"
                        />
                    </div>
                    <div>
                        <label htmlFor="duration" className="block text-sm font-medium text-gray-700 mb-1">Duration (in minutes)</label>
                        <input 
                            type="number" 
                            id="duration"
                            name="duration" 
                            value={drill.duration} 
                            onChange={handleChange} 
                            required 
                            className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-primary focus:border-primary"
                        />
                    </div>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">Skills</label>
                    <div className="flex flex-wrap gap-2">
                        {allowedSkills.map(skill => (
                            <button
                                key={skill}
                                type="button"
                                onClick={() => handleTagToggle(skill)}
                                className={`px-3 py-1 rounded-full text-sm font-semibold ${
                                    drill.tags.includes(skill)
                                    ? 'bg-primary text-white'
                                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                                } transition-colors duration-200`}
                            >
                                {skill}
                            </button>
                        ))}
                    </div>
                </div>
                <div>
                    <label htmlFor="equipment" className="block text-sm font-medium text-gray-700 mb-1">Equipment (comma-separated)</label>
                    <input 
                        type="text" 
                        id="equipment"
                        name="equipment" 
                        value={drill.equipment} 
                        onChange={handleChange} 
                        className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-primary focus:border-primary"
                    />
                </div>
                <div>
                    <label htmlFor="videoUrl" className="block text-sm font-medium text-gray-700 mb-1">Video URL</label>
                    <input 
                        type="url" 
                        id="videoUrl"
                        name="videoUrl" 
                        value={drill.videoUrl} 
                        onChange={handleChange} 
                        className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-primary focus:border-primary"
                    />
                </div>
                <button 
                    type="submit" 
                    className="w-full bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out mt-6"
                >
                    Create Drill
                </button>
            </form>
        </div>
    );
}

export default CreateDrill;