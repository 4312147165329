import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getPracticeSessions } from '../services/practiceSessionsService';
import { useAuth } from './AuthContext';

const PracticeSessionList = () => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    const fetchSessions = async () => {
        try {
            const data = await getPracticeSessions();
            setSessions(data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch practice sessions');
            setLoading(false);
        }
    };

    fetchSessions();
}, []);

  if (loading) return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="animate-pulse space-y-4">
        <div className="h-4 bg-gray-200 rounded w-1/4"></div>
        <div className="h-10 bg-gray-200 rounded w-1/2"></div>
        <div className="space-y-2">
          {[...Array(5)].map((_, index) => (
            <div key={index} className="h-20 bg-gray-200 rounded"></div>
          ))}
        </div>
      </div>
    </div>
  );

  if (error) return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <strong className="font-bold">Error:</strong>
        <span className="block sm:inline"> {error}</span>
      </div>
    </div>
  );

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6 text-primary">Practice Sessions</h1>
      <Link 
        to="/create-session" 
        className="inline-block mb-6 bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
      >
        Create New Session
      </Link>
      {sessions.length === 0 ? (
        <p className="text-gray-600 text-lg">No practice sessions found.</p>
      ) : (
        <ul className="space-y-4">
          {sessions.map((session) => (
            <li key={session._id} className="bg-white shadow-md rounded-lg p-6 hover:shadow-lg transition duration-300 ease-in-out">
              <Link to={`/sessions/${session._id}`} className="block">
                <div className="flex justify-between items-center mb-2">
                  <h2 className="text-xl font-semibold text-primary">
                    {new Date(session.date).toLocaleDateString()} - {session.time}
                  </h2>
                  <span className="bg-secondary text-white text-sm font-bold py-1 px-2 rounded">
                    {session.drills.length} drill{session.drills.length !== 1 ? 's' : ''}
                  </span>
                </div>
                <p className="text-gray-600">
                  <span className="font-semibold">Location:</span> {session.location}
                </p>
                {session.notes && (
                  <p className="text-gray-600 mt-2">
                    <span className="font-semibold">Notes:</span> {session.notes}
                  </p>
                )}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PracticeSessionList;