import React, { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';

function QuickAddPlayerModal({ onClose, onAddPlayer, initialName = '' }) {
  const [playerData, setPlayerData] = useState({
    name: initialName,
    position: '',
    jerseyNumber: '',
    age: '',
    user: ''
  });

  useEffect(() => {
    setPlayerData(prev => ({ ...prev, name: initialName }));
  }, [initialName]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPlayerData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddPlayer(playerData);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <div className="bg-white p-5 rounded-lg shadow-xl max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Quick Add Player</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block mb-1">Name</label>
            <input
              type="text"
              name="name"
              value={playerData.name}
              onChange={handleChange}
              required
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1">Position</label>
            <input
              type="text"
              name="position"
              value={playerData.position}
              onChange={handleChange}
              required
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1">Jersey Number</label>
            <input
              type="number"
              name="jerseyNumber"
              value={playerData.jerseyNumber}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1">Age</label>
            <input
              type="number"
              name="age"
              value={playerData.age}
              onChange={handleChange}
              required
              className="w-full p-2 border rounded"
            />
          </div>
          <button type="submit" className="w-full bg-primary text-white px-4 py-2 rounded hover:bg-primary-dark">
            Add Player
          </button>
        </form>
      </div>
    </div>
  );
}

export default QuickAddPlayerModal;