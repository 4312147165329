import React, { useEffect, useState } from 'react';
import { getDrills, getPopularDrills } from '../services/drillsService';
import { Link } from 'react-router-dom';
import { ChevronDownIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import Pagination from './Pagination';
import { useAuth } from '../pages/AuthContext';
import SubscriptionButton from '../components/SubscriptionButton';
import { ClockIcon, UserGroupIcon, AdjustmentsVerticalIcon, CubeIcon } from '@heroicons/react/24/outline';

function DrillList() {
    const [drills, setDrills] = useState([]);
    const [sortBy, setSortBy] = useState('default');
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(9);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(true);
    const { user, isPremium, checkSubscriptionStatus } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                await checkSubscriptionStatus(); // Check subscription status on page load
                const drillsData = await (sortBy === 'popular' ? getPopularDrills(currentPage, pageSize) : getDrills(currentPage, pageSize));
                setDrills(drillsData.drills);
                setTotalPages(drillsData.totalPages);
            } catch (err) {
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage, pageSize, sortBy, checkSubscriptionStatus]);
    
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        window.scrollTo(0, 0);
    };

    const renderDrillItem = (drill) => {
        const isFeaturedAndNotPremium = drill.isFeatured && !isPremium;
        const maxDescriptionLength = 200; // Adjust this value as needed

        const truncateDescription = (text) => {
            if (text.length <= maxDescriptionLength) return text;
            return (
                <>
                    {text.slice(0, maxDescriptionLength).trim()}...{' '}
                    <span className="font-bold text-primary hover:underline">Read More</span>
                </>
            );
        };
        
        return (
            <li key={drill._id} className="bg-white shadow-md rounded-lg overflow-hidden transition-all duration-300 mb-6 relative flex flex-col h-[400px]">
                <div className={`${isFeaturedAndNotPremium ? 'filter blur-[2px]' : ''} flex flex-col h-full`}>
                    <div className="p-6 flex-grow flex flex-col">
                        <div className="flex justify-between items-start mb-4">
                            <h2 className="text-xl font-semibold text-primary">{drill.title}</h2>
                            {drill.isFeatured && (
                                <span className="bg-yellow-400 text-yellow-800 text-xs font-bold px-2 py-1 rounded-full">
                                    Premium
                                </span>
                            )}
                        </div>
                        <p className="text-gray-600 mb-4 flex-grow line-clamp-3">
                            {truncateDescription(drill.description)}
                        </p>
                        <div className="grid grid-cols-2 gap-4 text-sm mt-auto">
                            <div className="flex items-center space-x-2">
                                <UserGroupIcon className="h-5 w-5 text-primary" />
                                <span>{drill.ageGroup}</span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <ClockIcon className="h-5 w-5 text-primary" />
                                <span>{drill.duration} min</span>
                            </div>
                            <div className="flex items-center space-x-2">
                                <AdjustmentsVerticalIcon className="h-5 w-5 text-primary" />
                                <span>{drill.difficulty}</span>
                            </div>
                            {drill.equipment.length > 0 && (
                                <div className="flex items-center space-x-2">
                                    <CubeIcon className="h-5 w-5 text-primary" />
                                    <span>{drill.equipment.join(', ')}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="p-6 pt-3 bg-gray-50">
                        <div className="flex flex-wrap gap-2">
                            {drill.tags.map((skill, index) => (
                                <span key={index} className="bg-primary bg-opacity-10 text-primary px-2 py-1 rounded-full text-xs">
                                    {skill}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
                {isFeaturedAndNotPremium ? (
                    <div className="absolute inset-0 bg-gray-900 bg-opacity-30 flex items-center justify-center">
                        <div className="text-center">
                            <LockClosedIcon className="h-12 w-12 text-white opacity-75 mx-auto mb-2" />
                            <SubscriptionButton className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out z-10 relative" />
                        </div>
                    </div>
                ) : (
                    <Link to={`/drills/${drill._id}`} className="block absolute inset-0">
                        <span className="sr-only">View drill details</span>
                    </Link>
                )}
            </li>
        );
    };

    if (loading) return (
        <div className="max-w-4xl mx-auto p-6">
          <div className="animate-pulse space-y-4">
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            <div className="h-10 bg-gray-200 rounded w-1/2"></div>
            <div className="space-y-2">
              {[...Array(5)].map((_, index) => (
                <div key={index} className="h-20 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
    );
    if (error) return <div className="text-red-600">Error: {error}</div>;

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-8 space-y-4 sm:space-y-0">
                <h1 className="text-3xl font-bold text-primary">Drills</h1>
                <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 w-full sm:w-auto">
                    <div className="relative w-full sm:w-auto">
                        <select
                            value={sortBy}
                            onChange={(e) => setSortBy(e.target.value)}
                            className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary text-sm"
                        >
                            <option value="default">Sort by: Default</option>
                            <option value="popular">Sort by: Most Popular</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <ChevronDownIcon className="h-4 w-4" />
                        </div>
                    </div>
                    <Link 
                        to="/create-drill" 
                        className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out w-full sm:w-auto text-center"
                    >
                        Create New Drill
                    </Link>
                </div>
            </div>
    
            {drills.length === 0 ? (
                <div className="text-center py-12">
                    <p className="text-gray-600 text-lg">No drills available</p>
                </div>
            ) : (
                <>
                    <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {drills.map(renderDrillItem)}
                    </ul>
                    <div className="mt-8">
                        <Pagination 
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </>
            )}
        </div>
    );
}

export default DrillList;