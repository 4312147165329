import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getPlayerById, assignDrillToPlayer } from '../services/playersService';
import { getDrills } from '../services/drillsService';

function AssignDrill() {
    const [player, setPlayer] = useState(null);
    const [drills, setDrills] = useState([]);
    const [selectedDrill, setSelectedDrill] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [playerData, drillsData] = await Promise.all([
                    getPlayerById(id),
                    getDrills()
                ]);
                setPlayer(playerData);
                setDrills(drillsData.drills);
                setLoading(false);
            } catch (err) {
                setError('Failed to load data');
                setLoading(false);
            }
        };
        fetchData();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedDrill) {
            setError('Please select a drill');
            return;
        }
        try {
            await assignDrillToPlayer(id, selectedDrill);
            navigate(`/players/${id}`);
        } catch (err) {
            console.error('Error assigning drill:', err);
            setError(err.details || err.message || 'Failed to assign drill');
        }
    };

    if (loading) return (
        <div className="max-w-4xl mx-auto p-6">
          <div className="animate-pulse space-y-4">
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            <div className="h-10 bg-gray-200 rounded w-1/2"></div>
            <div className="space-y-2">
              {[...Array(5)].map((_, index) => (
                <div key={index} className="h-20 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
      );
    if (error) return <div>{error}</div>;
    if (!player) return <div>Player not found</div>;

    return (
        <div className="max-w-2xl mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6 text-primary">Assign Drill to {player.name}</h1>
            {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                    <strong className="font-bold">Error: </strong>
                    <span className="block sm:inline">{error}</span>
                </div>
            )}
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="drill" className="block text-sm font-medium text-gray-700">Select Drill</label>
                    <select
                        id="drill"
                        value={selectedDrill}
                        onChange={(e) => setSelectedDrill(e.target.value)}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                    >
                        <option value="">Select a drill</option>
                        {drills.map((drill) => (
                            <option key={drill._id} value={drill._id}>{drill.title}</option>
                        ))}
                    </select>
                </div>
                <button type="submit" className="w-full bg-primary text-white py-2 px-4 rounded hover:bg-primary-dark transition duration-300">
                    Assign Drill
                </button>
            </form>
        </div>
    );
}

export default AssignDrill;