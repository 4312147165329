import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

const SessionTemplateOverview = ({ modules, onModuleClick, filteredDrillsForModule, isInPracticeBuilder = false }) => {
  return (
    <div className="space-y-4">
      <h3 className="text-xl font-semibold text-primary mb-4">Session Overview</h3>
      {modules.map((module, index) => (
        <div key={index} className="bg-white shadow rounded-lg p-4">
          <div className="flex justify-between items-center mb-2">
            <h4 className="font-medium text-primary">{module.name}</h4>
            <span className="text-gray-600">{module.duration} min</span>
          </div>
          <p className="text-gray-600 mb-2">{module.description || 'Focus on ' + module.suggestedSkills.join(', ')}</p>
          <div className="flex items-center justify-between mt-4">
            <div className="flex flex-wrap gap-2">
              {module.suggestedSkills.map((skill, idx) => (
                <span key={idx} className="bg-blue-100 text-blue-800 text-xs font-medium px-2 py-0.5 rounded">
                  {skill}
                </span>
              ))}
            </div>
            {isInPracticeBuilder && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  onModuleClick(module);
                }}
                className="flex items-center text-primary hover:text-primary-dark transition-colors duration-200"
                type="button" 
              >
                {module.drill ? 'Change Drill' : 'Select Drill'}
                <ChevronRightIcon className="h-5 w-5 ml-1" />
              </button>
            )}
          </div>
          {module.drill && (
            <div className="mt-3 p-3 bg-gray-50 rounded-md">
              <p className="font-medium text-gray-700">Selected: {module.drill.title}</p>
              <p className="text-sm text-gray-600">{module.drill.skillType} - {module.drill.duration} min</p>
            </div>
          )}
        </div>
      ))}
      <p className="text-right text-gray-600 font-medium">
        Total Duration: {modules.reduce((total, module) => total + module.duration, 0)} minutes
      </p>
    </div>
  );
};

export default SessionTemplateOverview;