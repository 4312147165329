import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { getPlayerByUserId, createPlayer, updatePlayer } from '../services/playersService';

function PlayerForm() {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [player, setPlayer] = useState({
        name: '',
        age: '',
        position: '',
        jerseyNumber: '',
        parentContact: '',
        notes: ''
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function fetchPlayer() {
            try {
                console.log(user);
                let data;
                if (user._id) {
                    data = await getPlayerByUserId(user._id);
                } else if (user.id) {
                    data = await getPlayerByUserId(user.id);
                }

                if (data) {
                    setPlayer(data);
                } else {
                    // If no player data is found, initialize with user's name
                    setPlayer(prevState => ({
                        ...prevState,
                        name: user.username || ''
                    }));
                }
                setLoading(false);
            } catch (err) {
                console.error('Error fetching player data:', err);
                setError('Failed to load player data');
                setLoading(false);
            }
        }
        fetchPlayer();
    }, [user]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPlayer(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let updatedPlayer;
            if (player._id) {
                updatedPlayer = await updatePlayer(player._id, player);
            } else {
                updatedPlayer = await createPlayer({ ...player, user: user._id || user.id });
            }
            console.log('Player saved:', updatedPlayer);
            navigate('/player-profile');
        } catch (err) {
            console.error('Error saving player data:', err);
            setError('Failed to save player data');
        }
    };

    if (loading) return (
        <div className="max-w-4xl mx-auto p-6">
          <div className="animate-pulse space-y-4">
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            <div className="h-10 bg-gray-200 rounded w-1/2"></div>
            <div className="space-y-2">
              {[...Array(5)].map((_, index) => (
                <div key={index} className="h-20 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
    );
    if (error) return <div>{error}</div>;

    return (
        <div className="max-w-2xl mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6 text-primary">{player._id ? 'Edit' : 'Create'} Player Profile</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block mb-1">Name</label>
                    <input type="text" name="name" value={player.name} onChange={handleChange} required className="w-full p-2 border rounded" />
                </div>
                <div>
                    <label className="block mb-1">Age</label>
                    <input type="number" name="age" value={player.age} onChange={handleChange} required className="w-full p-2 border rounded" />
                </div>
                <div>
                    <label className="block mb-1">Position</label>
                    <input type="text" name="position" value={player.position} onChange={handleChange} required className="w-full p-2 border rounded" />
                </div>
                <div>
                    <label className="block mb-1">Jersey Number</label>
                    <input type="number" name="jerseyNumber" value={player.jerseyNumber} onChange={handleChange} required className="w-full p-2 border rounded" />
                </div>
                <div>
                    <label className="block mb-1">Parent Contact</label>
                    <input type="text" name="parentContact" value={player.parentContact} onChange={handleChange} required className="w-full p-2 border rounded" />
                </div>
                <div>
                    <label className="block mb-1">Notes</label>
                    <textarea name="notes" value={player.notes} onChange={handleChange} className="w-full p-2 border rounded h-24" />
                </div>
                <button type="submit" className="bg-primary text-white px-4 py-2 rounded hover:bg-primary-dark">Save Profile</button>
            </form>
        </div>
    );
}

export default PlayerForm;