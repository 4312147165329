import api from './api';

export const getPlayers = async () => {
  try {
    const response = await api.get('/players');
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getPlayerById = async (id) => {
  try {
    const response = await api.get(`/players/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getPlayerByUserId = async (userId) => {
  try {
    const response = await api.get(`/players/user/${userId}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null; // Return null if player is not found
    }
    throw error.response.data;
  }
};

export const getRostersForPlayer = async (playerId) => {
  try {
    const response = await api.get(`/players/${playerId}/rosters`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const createPlayer = async (playerData) => {
  try {
    const response = await api.post('/players', playerData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const updatePlayer = async (id, playerData) => {
  try {
    const response = await api.put(`/players/${id}`, playerData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const deletePlayer = async (id) => {
  try {
    const response = await api.delete(`/players/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const checkCoachRights = async (coachId, playerId) => {
  try {
    console.log(`Checking coach rights for coach ${coachId} and player ${playerId}`);
    const response = await api.get(`/players/check-coach-rights/${coachId}/${playerId}`);
    console.log('Coach rights response:', response.data);
    return response.data.hasRights;
  } catch (error) {
    console.error('Error checking coach rights:', error.response ? error.response.data : error.message);
    // Instead of returning false, we'll throw an error to be handled by the component
    throw error;
  }
};

export const assignDrillToPlayer = async (playerId, drillId) => {
  try {
    const response = await api.post(`/players/${playerId}/assign-drill`, { drillId });
    return response.data;
  } catch (error) {
    console.error('Error in assignDrillToPlayer:', error.response?.data || error.message);
    throw error.response?.data || { message: 'An unexpected error occurred' };
  }
};