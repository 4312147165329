import api from './api';

export const createPracticeSession = async (sessionData) => {
  try {
    console.log('Data being sent to API:', sessionData);
    const response = await api.post('/sessions', sessionData);
    console.log('Response from API:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating practice session:', error);
    throw error;
  }
};

export const getPracticeSessions = async () => {
  try {
    const response = await api.get('/sessions');
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getPracticeSessionById = async (id) => {
  try {
    const response = await api.get(`/sessions/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const updatePracticeSession = async (id, sessionData) => {
  try {
    const response = await api.put(`/sessions/${id}`, sessionData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const deletePracticeSession = async (id) => {
  try {
    const response = await api.delete(`/sessions/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};