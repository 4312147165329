import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../pages/AuthContext';
import { CheckCircle } from 'lucide-react';

const SubscriptionSuccess = () => {
  const { checkSubscriptionStatus } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isUpdating, setIsUpdating] = useState(true);

  useEffect(() => {
    const updateSubscriptionStatus = async () => {
      const queryParams = new URLSearchParams(location.search);
      const sessionId = queryParams.get('session_id');

      if (sessionId) {
        try {
          await checkSubscriptionStatus();
        } catch (error) {
          console.error('Error updating subscription status:', error);
        }
      }

      setIsUpdating(false);
    };

    updateSubscriptionStatus();

    // Redirect after a delay
    const redirectTimer = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => clearTimeout(redirectTimer);
  }, [location, navigate, checkSubscriptionStatus]);

  return (
    <div className="min-h-screen flex flex-col bg-background">
      <div className="flex-grow flex items-start justify-center pt-20">
        <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-8 text-center">
          <div className="mb-6">
            <CheckCircle className="mx-auto h-16 w-16 text-primary animate-pulse" />
          </div>
          <h1 className="text-3xl font-bold mb-4 text-secondary">Subscription Successful!</h1>
          <p className="text-lg text-gray-600 mb-6">
            Thank you for upgrading to our premium features.
          </p>
          {isUpdating ? (
            <div className="flex justify-center items-center mb-4">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
            </div>
          ) : (
            <div className="text-sm text-gray-500">
              <p>Your account has been updated.</p>
              <p>You'll be redirected to the home page shortly.</p>
            </div>
          )}
          
          <div className="mt-8">
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 overflow-hidden">
              <div className="bg-primary h-2.5 rounded-full animate-fill-bar"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSuccess;