import React, { useState, useEffect, useMemo } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getPracticeSessionById, deletePracticeSession } from '../services/practiceSessionsService';
import { ChevronRightIcon } from 'lucide-react';

const PracticeSessionDetails = () => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const data = await getPracticeSessionById(id);
        setSession(data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch practice session details');
        setLoading(false);
      }
    };

    fetchSession();
  }, [id]);

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this session?')) {
      try {
        await deletePracticeSession(id);
        navigate('/sessions');
      } catch (err) {
        setError('Failed to delete practice session');
      }
    }
  };

  // Calculate total session duration
  const totalDuration = useMemo(() => {
    return session?.drills.reduce((total, drill) => total + drill.duration, 0) || 0;
  }, [session?.drills]);

  // Updated equipment list accumulation
  const equipmentList = useMemo(() => {
    const equipmentSet = new Set();
    session?.drills.forEach(drill => {
      drill.equipment.forEach(item => {
        // Trim the item and add only if it's not empty
        const trimmedItem = item.trim();
        if (trimmedItem) {
          equipmentSet.add(trimmedItem);
        }
      });
    });
    return Array.from(equipmentSet);
  }, [session?.drills]);

  // Function to convert military time to normalized time
  const normalizeTime = (time) => {
    const [hours, minutes] = time.split(':');
    const hour = parseInt(hours, 10);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const normalizedHour = hour % 12 || 12;
    return `${normalizedHour}:${minutes} ${ampm}`;
  };

  if (loading) return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="animate-pulse space-y-4">
        <div className="h-4 bg-gray-200 rounded w-1/4"></div>
        <div className="h-10 bg-gray-200 rounded w-1/2"></div>
        <div className="space-y-2">
          {[...Array(5)].map((_, index) => (
            <div key={index} className="h-20 bg-gray-200 rounded"></div>
          ))}
        </div>
      </div>
    </div>
  );
  if (error) return <div className="max-w-4xl mx-auto p-6 text-red-500">Error: {error}</div>;
  if (!session) return <div className="max-w-4xl mx-auto p-6">Session not found</div>;

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6 text-primary">Practice Session Details</h1>
      
      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <p className="text-gray-600">Date: <span className="font-semibold">{new Date(session.date).toLocaleDateString()}</span></p>
          </div>
          <div>
            <p className="text-gray-600">Time: <span className="font-semibold">{normalizeTime(session.time)}</span></p>
          </div>
          <div>
            <p className="text-gray-600">Location: <span className="font-semibold">{session.location}</span></p>
          </div>
          <div>
            <p className="text-gray-600">Total Duration: <span className="font-semibold">{totalDuration} minutes</span></p>
          </div>
        </div>
        
        <div className="mb-4">
          <h3 className="font-semibold text-lg mb-2">Notes:</h3>
          <p className="text-gray-600">{session.notes || 'No notes provided'}</p>
        </div>
        
        {equipmentList.length > 0 && (
          <div>
            <h3 className="font-semibold text-lg mb-2">Equipment Needed:</h3>
            <p className="text-gray-600">{equipmentList.join(', ')}</p>
          </div>
        )}
      </div>

      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-2xl font-bold mb-4 text-secondary">Drills</h2>
        {session.drills.length === 0 ? (
          <p className="text-gray-600">No drills added to this session.</p>
        ) : (
          <ul className="space-y-4">
            {session.drills.map((drill) => (
              <li key={drill._id} className="border-b pb-4">
                <Link 
                  to={`/drills/${drill._id}?sessionId=${session._id}`}
                  className="flex items-center justify-between hover:bg-gray-50 p-2 rounded transition duration-150 ease-in-out"
                >
                  <div>
                    <h3 className="text-xl font-semibold text-primary">{drill.title}</h3>
                    <p className="text-gray-600">Duration: <span className="font-semibold">{drill.duration} minutes</span></p>
                    <div className="flex flex-wrap gap-1 mt-1">
                      {drill.tags.map((skill, index) => (
                        <span key={index} className="bg-primary text-white px-2 py-1 rounded-full text-xs">
                          {skill}
                        </span>
                      ))}
                    </div>
                  </div>
                  <ChevronRightIcon className="text-gray-400" />
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="flex justify-between items-center">
        <Link 
          to="/sessions" 
          className="bg-secondary hover:bg-secondary-dark text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
        >
          Sessions
        </Link>
        <div className="space-x-2 flex">
          <Link 
            to={`/edit-session/${session._id}`} 
            className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out w-24 text-center"
          >
            Edit
          </Link>
          <button 
            onClick={handleDelete}
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out w-24"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default PracticeSessionDetails;