import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AuthProvider, useAuth } from './pages/AuthContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Navigation from './pages/Navigation';
import LandingPage from './pages/LandingPage';
import DrillList from './pages/DrillList';
import DrillDetails from './pages/DrillDetails';
import CreateDrill from './pages/CreateDrill';
import UpdateDrill from "./pages/UpdateDrill";
import PracticeSessionBuilder from './pages/PracticeSessionBuilder';
import PracticeSessionList from './pages/PracticeSessionList';
import PracticeSessionDetails from './pages/PracticeSessionDetails';
import EditPracticeSession from './pages/EditPracticeSession';
import PlayerList from './pages/PlayerList';
import PlayerForm from './pages/PlayerForm';
import PlayerProfile from './pages/PlayerProfile';
import RosterList from './pages/RosterList';
import RosterForm from './pages/RosterForm';
import RosterDetail from './pages/RosterDetail';
import Login from './pages/Login';
import Register from './pages/Register';
import AssignDrill from './pages/AssignDrill';
import SessionTemplateList from './pages/SessionTemplateList';
import SessionTemplateForm from './pages/SessionTemplateForm';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import UserProfileManagement from './pages/UserProfileManagement';
import SubscriptionSuccess from './components/SubscriptionSuccess';
import SubscriptionCancel from './components/SubscriptionCancel';

function PrivateRoute({ children, allowedRoles }) {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/login" />;
  }
  if (allowedRoles && !allowedRoles.includes(user.role)) {
    return <Navigate to="/" />;
  }
  return children;
}

function AppContent() {
  const { user } = useAuth();

  return (
    <div className="min-h-screen bg-gray-100">
      {user && (
        <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex items-center">
            <img src="/logo192.png" alt="Gaffer Logo" className="h-10 w-10 mr-4" />
            <h1 className="text-3xl font-bold text-gray-900">Gaffer</h1>
          </div>
        </header>
      )}
      {user && <Navigation />}
      <main className={user ? "max-w-7xl mx-auto py-6 sm:px-6 lg:px-8" : ""}>
        <div className={user ? "px-4 py-6 sm:px-0" : ""}>
          <Routes>
            <Route path="/" element={user ? <Navigate to="/drills" /> : <LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:resetToken" element={<ResetPassword />} />
            <Route path="/drills" element={<PrivateRoute><DrillList /></PrivateRoute>} />
            <Route path="/drills/:id" element={<PrivateRoute><DrillDetails /></PrivateRoute>} />
            <Route path="/create-drill" element={<PrivateRoute allowedRoles={['coach']}><CreateDrill /></PrivateRoute>} />
            <Route path="/update-drill/:id" element={<PrivateRoute allowedRoles={['coach']}><UpdateDrill /></PrivateRoute>} />
            <Route path="/create-session" element={<PrivateRoute allowedRoles={['coach']}><PracticeSessionBuilder /></PrivateRoute>} />
            <Route path="/edit-session/:id" element={<PrivateRoute allowedRoles={['coach']}><EditPracticeSession /></PrivateRoute>} />
            <Route path="/sessions" element={<PrivateRoute allowedRoles={['coach']}><PracticeSessionList /></PrivateRoute>} />
            <Route path="/sessions/:id" element={<PrivateRoute allowedRoles={['coach']}><PracticeSessionDetails /></PrivateRoute>} />
            <Route path="/players" element={<PrivateRoute allowedRoles={['coach']}><PlayerList /></PrivateRoute>} />
            <Route path="/create-player" element={<PrivateRoute allowedRoles={['coach']}><PlayerForm /></PrivateRoute>} />
            <Route path="/edit-player/:id" element={<PrivateRoute allowedRoles={['coach']}><PlayerForm /></PrivateRoute>} />
            <Route path="/rosters" element={<PrivateRoute allowedRoles={['coach']}><RosterList /></PrivateRoute>} />
            <Route path="/create-roster" element={<PrivateRoute allowedRoles={['coach']}><RosterForm /></PrivateRoute>} />
            <Route path="/edit-roster/:id" element={<PrivateRoute allowedRoles={['coach']}><RosterForm /></PrivateRoute>} />
            <Route path="/rosters/:id" element={<PrivateRoute><RosterDetail /></PrivateRoute>} />
            <Route path="/player-profile" element={<PrivateRoute allowedRoles={['player']}><PlayerProfile /></PrivateRoute>} />
            <Route path="/create-profile" element={<PrivateRoute allowedRoles={['player']}><PlayerForm /></PrivateRoute>} />
            <Route path="/edit-profile" element={<PrivateRoute allowedRoles={['player']}><PlayerForm /></PrivateRoute>} />
            <Route path="/players/:id" element={<PrivateRoute><PlayerProfile /></PrivateRoute>} />
            <Route path="/assign-drill/:id" element={<PrivateRoute allowedRoles={['coach']}><AssignDrill /></PrivateRoute>} />
            <Route path="/session-templates" element={<PrivateRoute allowedRoles={['coach']}><SessionTemplateList /></PrivateRoute>} />
            <Route path="/create-session-template" element={<PrivateRoute allowedRoles={['coach']}><SessionTemplateForm /></PrivateRoute>} />
            <Route path="/edit-session-template/:id" element={<PrivateRoute allowedRoles={['coach']}><SessionTemplateForm /></PrivateRoute>} />
            <Route path="/user-profile" element={<PrivateRoute><UserProfileManagement /></PrivateRoute>} />
            <Route path="/subscription/success" element={<SubscriptionSuccess />} />
            <Route path="/subscription/cancel" element={<SubscriptionCancel />} />
          </Routes>
        </div>
      </main>
    </div>
  );
}

function App() {
  return (
    <GoogleOAuthProvider clientId="675609829523-aqmbh7qmerk979tv4ql59v8maeh58cbl.apps.googleusercontent.com">
      <AuthProvider>
        <DndProvider backend={HTML5Backend}>
          <Router>
            <AppContent />
          </Router>
        </DndProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;