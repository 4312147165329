import React, { createContext, useState, useEffect, useContext } from 'react';
import api from '../services/api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userProfile, setUserProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isPremium, setIsPremium] = useState(false);

    useEffect(() => {
        loadUser();
    }, []);

    const loadUser = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const response = await api.get('/auth/verify');
                setUser(response.data.user);
                await loadUserProfile();
                await checkSubscriptionStatus();
            } catch (error) {
                console.error('Error verifying token:', error);
                localStorage.removeItem('token');
                delete api.defaults.headers.common['Authorization'];
            }
        }
        setLoading(false);
    };

    const loadUserProfile = async () => {
        try {
            const response = await api.get('/user-profile');
            setUserProfile(response.data);
        } catch (error) {
            console.error('Error loading user profile:', error);
        }
    };

    const checkSubscriptionStatus = async () => {
        try {
          const response = await api.get('/subscription/status');
          setIsPremium(response.data.isPremium);
          console.log('Subscription status:', response.data.isPremium);
          localStorage.setItem('isPremium', response.data.isPremium);
        } catch (error) {
          console.error('Error checking subscription status:', error);
        }
      };

    const login = async (userData, token) => {
        setUser(userData);
        localStorage.setItem('token', token);
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        await loadUserProfile();
        await checkSubscriptionStatus();
    };

    const updateUser = (userData) => {
        setUser(prevUser => ({ ...prevUser, ...userData }));
    };

    const logout = () => {
        setUser(null);
        setUserProfile(null);
        localStorage.removeItem('token');
        localStorage.removeItem('isPremium');
        delete api.defaults.headers.common['Authorization'];
    };

    return (
        <AuthContext.Provider value={{ user, userProfile, login, logout, loading, updateUser, loadUserProfile, isPremium, checkSubscriptionStatus }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);