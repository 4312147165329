import React, { useState, useEffect } from 'react';
import { XCircleIcon } from '@heroicons/react/24/outline';

const DrillSelectionModal = ({ isOpen, onClose, module, drills, onSelectDrill }) => {
  const [filteredDrills, setFilteredDrills] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setFilteredDrills(drills);
  }, [drills]);

  useEffect(() => {
    const filtered = drills.filter(drill => 
      drill.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      drill.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setFilteredDrills(filtered);
  }, [searchTerm, drills]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">{module.name}</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <XCircleIcon className="h-6 w-6" />
          </button>
        </div>
        <input
          type="text"
          placeholder="Search drills..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 mb-4 border rounded"
        />
        <div className="max-h-60 overflow-y-auto">
          {filteredDrills.map(drill => (
            <div 
              key={drill._id} 
              className="p-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => {
                onSelectDrill(drill);
                onClose();
              }}
            >
              <h4 className="font-medium">{drill.title}</h4>
              <p className="text-sm text-gray-600">
                Duration: {drill.duration} minutes
              </p>
              <div className="flex flex-wrap gap-1 mt-1">
                {drill.tags.map((skill, index) => (
                  <span key={index} className="bg-primary text-white px-2 py-1 rounded-full text-xs">
                    {skill}
                  </span>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DrillSelectionModal;