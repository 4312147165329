import api from './api';

export const getSessionTemplates = async () => {
    try {
      const response = await api.get('/session-templates');
      return response.data;
    } catch (error) {
      console.error('Error fetching session templates:', error);
      throw error;
    }
  };

export const getSessionTemplateById = async (id) => {
  try {
    const response = await api.get(`/session-templates/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const createSessionTemplate = async (templateData) => {
    try {
      const response = await api.post('/session-templates', templateData);
      return response.data;
    } catch (error) {
      console.error('Error creating session template:', error);
      throw error;
    }
  };

export const updateSessionTemplate = async (id, templateData) => {
  try {
    const response = await api.put(`/session-templates/${id}`, templateData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteSessionTemplate = async (id) => {
  try {
    const response = await api.delete(`/session-templates/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};