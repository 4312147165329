import React, { useState, useEffect } from 'react';
import { getDrillById, updateDrill } from '../services/drillsService';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../pages/AuthContext';

const allowedSkills = [
    'Dribbling',
    'Passing',
    'Shooting',
    'Defending',
    'Warm Up',
    'Ball Control',
    'Heading',
    'Tackling',
    'Positioning',
    'Speed',
    'Stamina',
    'Strength',
    'Agility',
    'Teamwork',
    'Communication'
  ];

function UpdateDrill() {
    const [drill, setDrill] = useState({
        title: '',
        description: '',
        duration: '',
        difficulty: '',
        ageGroup: '',
        tags: [],
        equipment: '',
        isFeatured: false
    });
    const [error, setError] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const { user } = useAuth();

    useEffect(() => {
        const fetchDrill = async () => {
            try {
                const data = await getDrillById(id);
                setDrill({
                    ...data,
                    equipment: data.equipment.join(', '),
                    isFeatured: data.isFeatured || false
                });
            } catch (err) {
                setError('Failed to fetch drill');
            }
        };
        fetchDrill();
    }, [id]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setDrill(prevDrill => ({
            ...prevDrill,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleTagToggle = (skill) => {
        setDrill(prevDrill => ({
            ...prevDrill,
            tags: prevDrill.tags.includes(skill)
                ? prevDrill.tags.filter(tag => tag !== skill)
                : [...prevDrill.tags, skill]
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (drill.tags.length === 0) {
            setError('Please select at least one skill');
            return;
        }
        try {
            const updatedDrill = await updateDrill(id, {
                ...drill,
                equipment: drill.equipment.split(',').map(item => item.trim()),
                isFeatured: drill.isFeatured
            });
            navigate(`/drills/${updatedDrill._id}`);
        } catch (err) {
            setError('Failed to update drill');
        }
    };

    if (error) return <div className="text-red-500 text-center">{error}</div>;
    if (!drill.title) return (
        <div className="max-w-4xl mx-auto p-6">
          <div className="animate-pulse space-y-4">
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            <div className="h-10 bg-gray-200 rounded w-1/2"></div>
            <div className="space-y-2">
              {[...Array(5)].map((_, index) => (
                <div key={index} className="h-20 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
      );

    return (
        <div className="max-w-2xl mx-auto p-6 bg-white shadow-lg rounded-lg">
            <h1 className="text-3xl font-bold mb-6 text-primary">Update Drill</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">Title</label>
                    <input 
                        type="text" 
                        id="title"
                        name="title" 
                        value={drill.title} 
                        onChange={handleChange} 
                        required 
                        className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-primary focus:border-primary"
                    />
                </div>
                <div>
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">Description</label>
                    <textarea 
                        id="description"
                        name="description" 
                        value={drill.description} 
                        onChange={handleChange} 
                        required 
                        className="w-full border border-gray-300 rounded-md shadow-sm p-2 h-24 focus:ring-primary focus:border-primary"
                    />
                </div>
                <div>
                    <label htmlFor="ageGroup" className="block text-sm font-medium text-gray-700 mb-1">Age Group</label>
                    <input 
                        type="text" 
                        id="ageGroup"
                        name="ageGroup" 
                        value={drill.ageGroup} 
                        onChange={handleChange} 
                        required 
                        className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-primary focus:border-primary"
                    />
                </div>
                <div>
                    <label htmlFor="difficulty" className="block text-sm font-medium text-gray-700 mb-1">Difficulty</label>
                    <input 
                        type="text" 
                        id="difficulty"
                        name="difficulty" 
                        value={drill.difficulty} 
                        onChange={handleChange} 
                        required 
                        className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-primary focus:border-primary"
                    />
                </div>
                <div>
                    <label htmlFor="duration" className="block text-sm font-medium text-gray-700 mb-1">Duration (in minutes)</label>
                    <input 
                        type="number" 
                        id="duration"
                        name="duration" 
                        value={drill.duration} 
                        onChange={handleChange} 
                        required 
                        className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-primary focus:border-primary"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">Skills</label>
                    <div className="flex flex-wrap gap-2">
                        {allowedSkills.map(skill => (
                            <button
                                key={skill}
                                type="button"
                                onClick={() => handleTagToggle(skill)}
                                className={`px-3 py-1 rounded-full text-sm font-semibold ${
                                    drill.tags.includes(skill)
                                    ? 'bg-primary text-white'
                                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                                } transition-colors duration-200`}
                            >
                                {skill}
                            </button>
                        ))}
                    </div>
                </div>
                <div>
                    <label htmlFor="equipment" className="block text-sm font-medium text-gray-700 mb-1">Equipment (comma-separated)</label>
                    <input 
                        type="text" 
                        id="equipment"
                        name="equipment" 
                        value={drill.equipment} 
                        onChange={handleChange} 
                        className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-primary focus:border-primary"
                    />
                </div>
                <div>
                    <label htmlFor="videoUrl" className="block text-sm font-medium text-gray-700 mb-1">Video URL</label>
                    <input 
                        type="url" 
                        id="videoUrl"
                        name="videoUrl" 
                        value={drill.videoUrl} 
                        onChange={handleChange} 
                        className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-primary focus:border-primary"
                        placeholder="https://www.youtube.com/watch?v=..."
                    />
                </div>
                {user && user.role === 'coach' && (
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            id="isFeatured"
                            name="isFeatured"
                            checked={drill.isFeatured}
                            onChange={handleChange}
                            className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
                        />
                        <label htmlFor="isFeatured" className="ml-2 block text-sm text-gray-900">
                            Featured Drill (Premium)
                        </label>
                    </div>
                )}

                <button 
                    type="submit" 
                    className="w-full bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out mt-6"
                >
                    Update Drill
                </button>
            </form>
        </div>
    );
}

export default UpdateDrill;