import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getPlayers, deletePlayer } from '../services/playersService';
import { TrashIcon, PencilIcon } from '@heroicons/react/24/outline';

function PlayerList() {
    const [players, setPlayers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchPlayers();
    }, []);

    const fetchPlayers = async () => {
        try {
            const data = await getPlayers();
            setPlayers(data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch players');
            setLoading(false);
        }
    };

    const handleDelete = async (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        if (window.confirm('Are you sure you want to delete this player?')) {
            try {
                await deletePlayer(id);
                setPlayers(players.filter(player => player._id !== id));
            } catch (err) {
                setError('Failed to delete player');
            }
        }
    };

    if (loading) return (
        <div className="max-w-4xl mx-auto p-6">
          <div className="animate-pulse space-y-4">
            <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            <div className="h-10 bg-gray-200 rounded w-1/2"></div>
            <div className="space-y-2">
              {[...Array(5)].map((_, index) => (
                <div key={index} className="h-20 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
      );
      
    if (error) return <div>{error}</div>;

    return (
        <div className="max-w-4xl mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6 text-primary">Players</h1>
            <Link to="/create-player" className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded mb-4 inline-block">
                Add New Player
            </Link>
            <ul className="space-y-4">
                {players.map(player => (
                    <li key={player._id} className="bg-white shadow rounded-lg hover:shadow-lg transition duration-300 ease-in-out">
                        <Link to={`/players/${player._id}`} className="block">
                            <div className="p-4">
                                <div className="flex justify-between items-center mb-2">
                                    <h2 className="text-xl font-semibold text-primary">
                                        {player.name}
                                    </h2>
                                    <span className="bg-secondary text-white text-sm font-bold py-1 px-2 rounded">
                                        #{player.jerseyNumber}
                                    </span>
                                </div>
                                <p className="text-gray-600">Position: {player.position}</p>
                                <p className="text-gray-600">Age: {player.age}</p>
                            </div>
                        </Link>
                        <div className="px-4 py-2 bg-gray-50 rounded-b-lg flex justify-end space-x-2">
                            <Link 
                                to={`/edit-player/${player._id}`}
                                className="text-blue-500 hover:text-blue-700"
                                title="Edit player"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <PencilIcon className="h-5 w-5" />
                            </Link>
                            <button 
                                onClick={(e) => handleDelete(e, player._id)} 
                                className="text-gray-500 hover:text-red-500"
                                title="Delete player"
                            >
                                <TrashIcon className="h-5 w-5" />
                            </button>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default PlayerList;